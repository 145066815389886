import React from "react";
import {Box, Typography} from "@mui/material";

interface CardProps {
    text?: string;
    number?: number;
}


export const StatCard = React.memo<CardProps>(({text, number}) => {
        return (
           <Box sx={{
               paddingY:'12px',
               paddingX:'12px',
               backgroundColor: 'white',
               display: 'flex',
               flexGrow:1,
               flexDirection: 'column',
               width : {
                   xs:'100%',
                   lg: '190px'
               },
               // height: {
               //     xs:'100%',
               //     lg: '114px'
               //
               // }

           }}>
               <Box sx={{
                   width: '100%',
                   height: '100%',
                   display: 'flex',
                   flexDirection: 'column',
                   flexGrow: 1,
                   justifyContent: 'space-between',
               }}>
                   <Typography
                       align="center"
                       sx={{
                           fontSize: '14px',
                           fontWeight: 500,
                           lineHeight: '24px',
                       }}
                   >
                       {text}
                   </Typography>

                   <Typography
                       align="center"
                       sx={{
                           fontSize: '32px',
                           fontWeight: 600,
                           lineHeight: '48px',
                           color: '#3337FF',
                       }}
                   >
                       {!isNaN(number) ? number : '0'}
                   </Typography>
               </Box>
           </Box>
        )
    })


//     < div
// className = {'w-1/3  flex flex-col justify-center items-center rounded-2xl border-2 border-blue-primary shadow-2xl p-2'} >
//     < p
// className = {'text-gray-light text-center truncate'} > {text}
// </p>
// <div className={'flex justify-around'}>
//     <div className={'flex justify-center items-center w-1/2 pr-2'}>
//         <span className={'text-xl font-bold'}>{Math.ceil(number)}</span>
//     </div>
//
//     {/*<span className={'text-blue-middle w-1/2 font-bold'}>*/}
//     {/*    {`0 % from last week`}*/}
//     {/*</span>*/}
// </div>
// </div>