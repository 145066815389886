import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Tables} from "../../Table";
import {Form, Field, FormSpy} from "react-final-form";
import {FORM_ERROR, FormApi} from "final-form";
import api from "../../../Api";
import {VIDEOS_PATH} from "../../../NetService/doctorConstants";
import {useSnackbar} from "notistack";
import {useDoctorWizard} from "../../../hooks/doctorUseWizard";
import TextField from "@mui/material/TextField";
import {Box, IconButton, Menu, MenuItem, Tooltip, Typography} from "@mui/material";
import {MediaCardDoctor} from "../../MediaCardDoctor";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import {AddVideo} from "../../PopUps/AddVideo";

interface VideoData {
    age: ("3-5" | "6-12" | "13-18" | "19-35" | "36+")[]; // Define possible age categories
    category: "Cartoon" | "Nature" | "360VR" | "Game" | "Other"; // Define possible categories
    channelTitle: string;
    createdAt: string;
    id: string;
    image: string;
    title: string;
    updatedAt: string;
    url: string;
    youtubeId: string
}
type FormValues = {
    url: string,
    title: string,
    age: string,
    category: string,
}
export const ContentList = React.memo(() => {
        const [data, setData] = React.useState<VideoData[]>([]);
        const [dataState, setDataState] = React.useState<VideoData[]>([]);
        const [edit, setEdit] = React.useState<VideoData | undefined>();
        const [isOpen, setIsOpen] = useState(false);
        const [next, State] = useDoctorWizard();
        const oldData = data
        const fetchData = async () => {
            try {
                const result = await api.getApi(`${VIDEOS_PATH}?sort={"createdAt":-1}`);
                setData(result.results);
                setDataState(result.results)
            } catch (error) {
                console.error("Error", error);
            }
        };
        //menu
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const open = Boolean(anchorEl);
        const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
            setAnchorEl(event.currentTarget);
        };
        //error
        const handleClose = () => {
             setAnchorEl(null);
        };



    //AddVideoPopUp
        const handleOpen = useCallback((open,update?) => {
             setIsOpen(open);
            if(update){
                fetchData()
            }
         }, []);


    useEffect(() => {
        fetchData()
    }, []);

    let debounceTimeout;
    const handelSearch = (e)=>{

            const search = e.target.value;
            if(!search){
                setData(dataState)
                return;
            }else{
                clearTimeout(debounceTimeout);
                debounceTimeout = setTimeout(() => {
                    const filteredData =  data.filter((value)=>{
                        if(value.title.includes(search)){
                            return value;
                        }
                    })
                    setData(filteredData);

                }, 1000);
            }

        };
    const headers = [
        // {
        //     field: "id",
        //     headerName: "Video ID",
        //     width: 100,
        //     headerClassName: "table-header"
        // },
        // {
        //     field: "#",
        //     headerName: "#",
        //     width:'10%',
        //     headerClassName: "table-header"
        // },
        {
            field: "age",
            headerName: "Age range",
            width: '10%',

            cellStyle: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
            headerClassName: "table-header",
            renderCell: (param:any)=>{
                if(param.row.age.length > 1){
                    return param.row.age.join(", ");
                }else {
                    return param.row.age;
                }
            }
        },

        {
            field: "url",
            headerName: "Video",
            width: '30%',
            height: 20,
            headerClassName: "table-header",
            renderCell: (param: any) => {
                const url = param.value;
                return (
                    <div style={{
                        display: "flex",
                        color: '#3337FF',
                        alignItems:'center'
                    }}>
                        <img style={{width: "64px", height: "38px", marginRight: "5px", borderRadius: '8px'}} src={param.row.image} alt={param.row.title} />
                        <a className={'truncate'} href={url} target={"_blank"} rel={"noreferrer"}>{url}</a>
                    </div>
                );
            }

        },
        {
            field: "category",
            headerName: "Category",
            width: '10%',
            headerClassName: "table-header"
        },
        {
            field: "title",
            headerName: "Name",
            cellStyle: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
            width: '100%',
            headerClassName: "table-header"
        },
    ];

    const editHeader = [
        {
            field: "actions",
            headerName: "Actions",
            cellStyle: {
                position: 'absolute',
                right: '0px',
            },
            width: 80,
            sortable: false,
            disableColumnMenu: true,
            headerClassName: "table-header",
            renderCell: (params: any) => {
                const onEdit = () => {
                    handleOpen(true);

                    setEdit(params);
                };
                return params.value.product ? (
                    <Box display="flex">
                        <Tooltip title="Edit" arrow>
                            <IconButton
                                disableFocusRipple
                                onClick={onEdit}
                                sx={{ color: '#444A6D' }}
                                color="primary"
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete" arrow>
                            <IconButton disableFocusRipple sx={{ color: '#444A6D' }}>
                                <DeleteForeverOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                ) : (
                    <></>
                );
            }
        }
    ]





        let videosCount = useMemo(() => {
            let count = {
                "3-5": {    Cartoon:   0,  Nature: 0, "360VR": 0, Game: 0, Other: 0, Relax: 0, Program: 0 },
                "6-12": {   Cartoon:  0, Nature: 0, "360VR": 0, Game: 0, Other: 0, Relax: 0, Program: 0 },
                "13-18": {  Cartoon: 0, Nature: 0, "360VR": 0, Game: 0, Other: 0, Relax: 0, Program: 0 },
                "19-35": {  Cartoon: 0, Nature: 0, "360VR": 0, Game: 0, Other: 0, Relax: 0, Program: 0 },
                "36+": {    Cartoon: 0, Nature: 0, "360VR": 0, Game: 0, Other: 0, Relax: 0, Program: 0 },

            };
            if (data.length) {
                data.forEach((item: VideoData) => {
                    item.age.forEach((age) => {
                        count[age][item.category] = count[age][item.category] + 1;
                    });
                });
            }

            return count;
        }, [data]);

       return(
           <>
               <Box sx={{
                   display: 'flex',
                   flexDirection: 'column',
                   padding: '16px',
                   gap: '16px',
               }}>
                   <MediaCardDoctor videoData={videosCount}/>

                   <Box sx={{
                       display: 'flex',
                       flexDirection: 'column',
                       position: 'relative',
                       padding: '16px',
                       gap:'16px',
                       backgroundColor: 'white',
                        }}>
                       <Box sx={{
                           display: 'flex',
                           justifyContent: 'space-between',

                       }}>
                           <Typography sx={
                               {
                                   fontWeight: '500',
                                   fontSize: '16px',
                                   lineHeight: '24px',
                               }
                           }>Added Videos</Typography>
                           <Box display="flex">
                               <Typography sx={{
                                   cursor: 'pointer',
                                   color: 'rgba(51, 55, 255, 1)',
                                   fontWeight: '500',
                                   fontSize: '14px',
                                   lineHeight: '24px',
                               }} onClick={()=>handleOpen(true)}>Add New Video </Typography>
                               <Typography><AddIcon sx={{
                                   cursor: 'pointer',
                                   width: '16px',
                                   height: '16px',
                                   color: 'rgba(51, 55, 255, 1)',
                               }}/></Typography>
                           </Box>
                           {isOpen && <AddVideo isOpen={handleOpen} video={edit}/>}

                       </Box>
                       <Box sx={{
                           display: 'flex',
                           justifyContent: 'space-between',
                           alignItems: 'flex-center',
                       }}>
                           {/*findeme*/}
                           <TextField
                               margin="none"
                               fullWidth
                               name="search"
                               placeholder="Search title"
                               onChange={handelSearch}
                               slotProps={{
                                   input: {
                                       startAdornment: (
                                           <InputAdornment position="start">
                                               <SearchIcon sx={{color: "#444A6D"}}/>
                                           </InputAdornment>

                                       ),
                                   },
                               }}
                               sx={{

                                   '&::placeholder': {
                                        color:  'rgba(68, 74, 109, 1)',
                                        fontWeight: '400',
                                        fontsize: '16px',
                                       lineHeight: '24px',
                               },
                                   '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                       borderColor: 'rgba(13, 17, 242, 1)',
                                   },

                                   width: "210px",
                                   height: "56px",
                                   paddingTop: 0
                               }}

                           />
                           <Box sx={{
                               display: 'flex',
                               alignItems: 'center',
                               gap: '8px'
                           }}>
                                 <FilterAltIcon sx={{color: "#444A6D", width: '16px', height: '16px'}}  />
                                 <Typography>Filter by:</Typography>
                               <Box>

                                   <Box onClick={handleClick}>
                                       <ArrowDropDownIcon />
                                   </Box>
                                   <Menu
                                       id="composition-button"
                                       aria-labelledby="composition-button"
                                       anchorEl={anchorEl}
                                       open={open}
                                       onClose={handleClose}
                                       anchorOrigin={{
                                           vertical: 'top',
                                           horizontal: 'left',
                                       }}
                                       transformOrigin={{
                                           vertical: 'top',
                                           horizontal: 'left',
                                       }}
                                   >
                                       <MenuItem onClick={handleClose}>3-5</MenuItem>
                                       <MenuItem onClick={handleClose}>6-12</MenuItem>
                                       <MenuItem onClick={handleClose}>13-18</MenuItem>
                                       <MenuItem onClick={handleClose}>19-35</MenuItem>
                                       <MenuItem onClick={handleClose}>36+</MenuItem>
                                   </Menu>
                               </Box>

                               {/*<Form*/}
                               {/*    onSubmit={(values) => {*/}
                               {/*        console.log('Form submitted with:', values);*/}
                               {/*    }}*/}
                               {/*    render={({ handleSubmit, form, submitting, pristine }) => (*/}
                               {/*        <form onSubmit={handleSubmit}>*/}
                               {/*            <Select*/}
                               {/*                name={'age'}*/}
                               {/*                labelId="demo-simple-select-autowidth-label"*/}
                               {/*                id="demo-simple-select-autowidth"*/}
                               {/*                autoWidth*/}
                               {/*                label="Age"*/}

                               {/*            >*/}

                               {/*                <MenuItem value={20}>3-5</MenuItem>*/}
                               {/*                <MenuItem value={21}>6-12</MenuItem>*/}
                               {/*                <MenuItem value={22}>13-18</MenuItem>*/}
                               {/*                <MenuItem value={22}>19-35</MenuItem>*/}
                               {/*                <MenuItem value={22}>36+</MenuItem>*/}
                               {/*            </Select>*/}
                               {/*        </form>*/}
                               {/*    )}*/}
                               {/*/>*/}

                           </Box>
                       </Box>
                       <Box>
                           <Tables headers={headers} rows={data} editHeader={editHeader}/>
                       </Box>
                   </Box>
               </Box>

           </>
       )
    }
);