import * as React from 'react';
import Box from '@mui/material/Box';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import QuickreplyIcon from '@mui/icons-material/Quickreply';import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MenuIcon from '@mui/icons-material/Menu';
import {useDoctorWizard} from "../../hooks/doctorUseWizard";
import Button from "@mui/material/Button";
import {Menu, MenuItem} from "@mui/material";
import {useCallback, useContext, useState} from "react";
import {AuthContext} from "../../context/Authprovider";
import {useNavigate} from "react-router-dom";
import {SigOut} from "../PopUps/SigOut";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";

export const BottomBar = ()=>{
    const [next, State] = useDoctorWizard();
    const navigate = useNavigate();
    const {setIsLoggedIn} = useContext(AuthContext);
    const path = () => window.location.pathname.substring(1);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [isOpen, setIsOpen] = useState(false);


    const logout = () => {
        localStorage.removeItem("DoctorToken");
        localStorage.removeItem("Keppy-doctor");
        setIsLoggedIn(false);
        navigate("/doctorAdmin/logIn")
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (nextPage?:string) => {
        setAnchorEl(null);
        if(nextPage === 'ref' || nextPage === 'sms-conf' || nextPage === 'contactus') {
            next(nextPage);
        }
    };
    const  sinOut = useCallback((out) => {
        setIsOpen(false);
        if(out){
            logout()
        }
    },[])


    return(
        <Box sx={{
            zIndex: 1,
            width: '100%',
            position: 'absolute',
            bottom: 0,
        }}><Box sx={{ width: '100%' }}>
            <BottomNavigation
                showLabels
                sx={{
                    height: '80px',
                    backgroundColor: ' rgba(18, 20, 33, 1)',
                    gap: '8px'
                }}
            >
                <BottomNavigationAction
                    onClick={()=>{
                        next('statistics')
                    }}

                    sx={{
                        padding: '16px',
                        position: 'relative',
                    "& .MuiBottomNavigationAction-label": {
                        fontSize: "14px",       // Change font size
                        fontWeight: "500",      // Change font weight
                        color: path() === 'doctorAdmin/statistics'?  'rgba(122, 125, 255, 1)'  : 'white'
                    },}} label="Statistics" icon={
                    <Box>
                        <Box sx={{
                            display: path() === 'doctorAdmin/statistics'?  ''  : 'none',
                            position: 'absolute',
                            top: '2px',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '40px',
                            height: '5px',
                            backgroundColor: path() === 'doctorAdmin/statistics'?  'rgba(122, 125, 255, 1)'  : '',
                            borderBottomLeftRadius: '12px',
                            borderBottomRightRadius: '12px',
                        }}/>
                         <InsertChartIcon sx={{
                            color: path() === 'doctorAdmin/statistics'?  'rgba(122, 125, 255, 1)'  : 'white'
                         }} />
                     </Box>} />


                <BottomNavigationAction
                    onClick={()=>{
                        next('patients')
                    }}
                    sx={{
                        position: 'relative',
                        "& .MuiBottomNavigationAction-label": {
                            fontSize: "14px",
                            fontWeight: "500",
                            color: path() === 'doctorAdmin/patients'?  'rgba(122, 125, 255, 1)'  : 'white'
                        }
                    }}
                    label="Patients" icon={
                    <Box>
                        <Box sx={{
                            display: path() === 'doctorAdmin/patients'?  ''  : 'none',
                            position: 'absolute',
                            top: '2px',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '40px',
                            height: '5px',
                            backgroundColor: path() === 'doctorAdmin/patients'?  'rgba(122, 125, 255, 1)'  : '',
                            borderBottomLeftRadius: '12px',
                            borderBottomRightRadius: '12px',
                        }}/>
                        <QuickreplyIcon sx={{ color: path() === 'doctorAdmin/patients'?  'rgba(122, 125, 255, 1)'  : 'white'}}/>
                    </Box>} />

                <BottomNavigationAction
                    onClick={()=>{
                        next('sms-conf')
                    }}
                    sx={{
                        position: 'relative',
                        "& .MuiBottomNavigationAction-label": {
                            fontSize: "14px",       // Change font size
                            fontWeight: "500",      // Change font weight
                            color: path() === 'doctorAdmin/sms-conf'?  'rgba(122, 125, 255, 1)'  : 'white'
                        },}}
                    label="Communication" icon={
                    <Box>
                        <Box sx={{
                            display: path() === 'doctorAdmin/sms-conf'?  ''  : 'none',
                            position: 'absolute',
                            top: '2px',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '40px',
                            height: '5px',
                            backgroundColor: path() === 'doctorAdmin/sms-conf'?  'rgba(122, 125, 255, 1)'  : '',
                            borderBottomLeftRadius: '12px',
                            borderBottomRightRadius: '12px',
                        }}/>
                        <QuickreplyIcon
                        sx={{
                            color: path() === 'doctorAdmin/sms-conf'?  'rgba(122, 125, 255, 1)'  : 'white'

                        }}/>
                    </Box>} />

                <BottomNavigationAction
                    onClick={()=>{
                        next('content')
                    }}
                    sx={{
                        position: 'relative',
                        "& .MuiBottomNavigationAction-label": {
                            fontSize: "14px",       // Change font size
                            fontWeight: "500",      // Change font weight
                            color: path() === 'doctorAdmin/content'?  'rgba(122, 125, 255, 1)'  : 'white'}
                    }}
                    label="Media" icon={
                    // doctorAdmin/content
                    <Box>
                        <Box sx={{
                            display: path() === 'doctorAdmin/content'?  ''  : 'none',
                            position: 'absolute',
                            top: '2px',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '40px',
                            height: '5px',
                            backgroundColor: path() === 'doctorAdmin/content'?  'rgba(122, 125, 255, 1)'  : '',
                            borderBottomLeftRadius: '12px',
                            borderBottomRightRadius: '12px',
                        }}/>
                        <PermMediaIcon
                            sx={{
                                color: path() === 'doctorAdmin/content'?  'rgba(122, 125, 255, 1)'  : 'white'
                            }}/>
                    </Box>} />

                <BottomNavigationAction
                    label="More"
                    sx={{
                        color: "white",
                    }}
                    icon={
                        <Box>
                            <Box
                                id="demo-positioned-button"
                                aria-controls={open ? 'demo-positioned-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                sx={{ cursor: 'pointer', display: 'inline-flex' }}
                            >
                                <MenuIcon
                                    sx={{
                                        color: open ? 'rgba(122, 125, 255, 1)' : 'white',
                                    }}
                                />
                            </Box>
                            <Menu
                                id="composition-button"
                                aria-labelledby="composition-button"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <MenuItem onClick={() => handleClose('contactus')}>
                                    <Box display="flex" gap={'12px'}>
                                        <PermPhoneMsgIcon
                                            sx={{
                                                fontSize: 24,
                                                color: 'rgba(68, 74, 109, 1)'
                                            }}/>
                                        Contact us
                                    </Box>
                                </MenuItem>
                                <MenuItem onClick={() => handleClose('ref')}>
                                    <Box display="flex" gap={'12px'}>
                                        <ConnectWithoutContactIcon
                                            sx={{
                                                fontSize: 24,
                                                color:'rgba(68, 74, 109, 1)'
                                            }}/> Refer to
                                    </Box></MenuItem>
                                <MenuItem onClick={() => setIsOpen(true)}>Sign out</MenuItem>
                            </Menu>
                        </Box>

                    }
                />
            </BottomNavigation>
            {isOpen && <SigOut isOpen={sinOut} />}
        </Box>

        </Box>
    )
}




