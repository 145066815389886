import {Box, IconButton, Menu, MenuItem, Tooltip} from "@mui/material";
import React, {useEffect} from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import api from "../../../Api";
import {STATISTICS_PATH} from "../../../NetService/doctorConstants";
import {Tables} from "../../Table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
type IProcedur = {

    customer: {
        age: string,
        gender: string,
    }
    user: {
        firstName: string,
        lastName: string,
        age?: string,
        gender?: string,
        phoneNumber: string
    }
    product: {
        id: string
    }
    experience: number
    duration: number
    failed: string,
    url: string,
    own: boolean,
    createdAt: string
    followup: boolean,
    same: boolean
    procedureType: string,
    qr?: string,
    procedure: number,
    problem: number,
}
type TableState = {
    createdAt: string;
    product: string;
    qr: string;
    name: string;
    age: string;
    gender: string;
    phoneNumber: string;
    own: string;
    url: string;
    failed: string;
    duration: string;
    experience: string;
    procedure: string;
    problem: number;
    followup: string;
    same: string;
    procedureType: string;
}


export const Patients = React.memo(() =>{
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [tableData, setTableData] = React.useState<TableState[]>();
    const [state, setState] = React.useState<TableState[]>();
    const [filter,setFilter] = React.useState("");
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };
    useEffect(()=>{
        if(!filter) return
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            let startDate;

            if (filter === 'today') {
                startDate = new Date(today);
            } else if (filter === 'yesterday') {
                startDate = new Date(today);
                startDate.setDate(today.getDate() - 1);
            } else if (filter === 'last7days') {
                startDate = new Date(today);
                startDate.setDate(today.getDate() - 7);
            } else if (filter === 'last14days') {
                startDate = new Date(today);
                startDate.setDate(today.getDate() - 14);
            } else {
                setTableData(state);
                return
            }
            const filteredData =  tableData.filter(item => {
                const [day, month, year] = item.createdAt.split('.');
                const itemDate = new Date(`${year}-${month}-${day}`);
                itemDate.setHours(0, 0, 0, 0);
                return itemDate >= startDate && itemDate <= today;
            });
        console.log(filteredData);
            setTableData(filteredData);
    },[filter])

    const handleClose = (filter?) => {
        setAnchorEl(null);
        if(filter){
            setFilter(filter);
        }
    };

    const procedureDataProcessing = (data: IProcedur[]) => {

        const processed = data.map((item: IProcedur) => {
            return {
                createdAt: (() => {
                    const date = new Date(item.createdAt);
                    const day = String(date.getDate()).padStart(2, '0');
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const year = date.getFullYear();
                    return `${day}.${month}.${year}`;
                })(),
                product: item.product.id,
                qr: item.qr ? item.qr : 'false',
                name: `${item.user.firstName} ${item.user.lastName}`,
                age: item.customer.age,
                gender: item.customer.gender,
                phoneNumber: item.user.phoneNumber,
                own: `${item.own}`,
                url: item.url,
                failed: `${item.failed}`,
                duration: `${item.duration}`,
                experience: `${item.experience}`,
                procedure: `${item.procedure}`,
                problem: item.problem,
                followup: `${item.followup}`,
                same: `${item.same}`,
                procedureType: `${item.procedureType}`,
            }
        })
        setTableData(processed)
        setState(processed)

    }
    useEffect(() => {
        async function getData() {
            try {
                const result = await api.getApi(`${STATISTICS_PATH}?limit=100&sort={"createdAt":-1}&include=user,customer,product`);
                procedureDataProcessing(result.results);
            } catch (e) {
                console.log(e);
            }
        }

        getData();
    }, []);
    const headers = [
        {
            field: "name",
            headStyle:{
                width: "10%",
            },
            cellStyle: {
                whiteSpace: "nowrap",
            },
            headerName: "Name",
            headerClassName: "table-header"
        },
        {
            field: "createdAt",
            headerName: "Created",
            headStyle:{
                width: "8%",
            },
            cellStyle: {
                whiteSpace: "nowrap",
            },
            headerClassName: "table-header"
        },
        {
            field: "qr",
            headerName: "QR Scan",
            headStyle:{
                width: "8%",
            },
            cellStyle: {
                whiteSpace: "nowrap",
            },
            headerClassName: "table-header"
        },
        {
            field: "age",
            headerName: "Age",
            headStyle:{
                width: "8%",
            },
            cellStyle: {
                whiteSpace: "nowrap",
            },
            headerClassName: "table-header"
        },
        {
            field: "own",
            headerName: "Own",
            headStyle:{
                width: "8%",

            },
            cellStyle: {
                whiteSpace: "nowrap",
            },
            headerClassName: "table-header"
        },
        {
            field: "url",
            headerName: "Media",
            headStyle:{
                width: "20%",
            },
            cellStyle: {
                width: "120px",
                whiteSpace: "nowrap",
            },
            renderCell: (param:any) => {
                return(
                    <a  href={param.value}  target={'_blank'}  style={{
                        color: 'rgba(51, 55, 255, 1)'
                    }}>{param.value}</a>
                )
            },
            headerClassName: "table-header"
        },
        {
            field: "failed",
            headerName: "Failed",
            headStyle:{
                width: "8%",
            },
            cellStyle: {
                whiteSpace: "nowrap",
            },
            headerClassName: "table-header"
        },
        {
            field: "experience",
            headerName: "Experience",
            headStyle:{
                width: "8%",
            },
            cellStyle: {
                whiteSpace: "nowrap",
            },
            headerClassName: "table-header"
        },
        {
            field: "procedure",
            headerName: "Procedure",
            headStyle:{
                width: "8%",
            },
            cellStyle: {
                whiteSpace: "nowrap",
            },
            headerClassName: "table-header"
        },
        {
            field: "problem",
            headerName: "Problem",
            headStyle:{
                width: "8%",
                textAlign: "center",

            },
            cellStyle: {
                whiteSpace: "nowrap",
            },
            headerClassName: "table-header"
        },
        {
            field: "followup",
            headerName: "Follow Up",
            headStyle:{

                whiteSpace: "break-spaces",
                width: "8%",
            },
            cellStyle: {
                whiteSpace: "nowrap",
            },
            headerClassName: "table-header"
        },
        {
            field: "same",
            headerName: "Same",
            headStyle:{
                width: "8%",
            },
            cellStyle: {
                whiteSpace: "nowrap",
            },
            headerClassName: "table-header"
        },

    ]
    const editHeader = [
        {
            field: "actions",
            headerName: "Actions",
            cellStyle: {
                position: 'absolute',
                right: '0px',
            },
            width: 80,
            sortable: false,
            disableColumnMenu: true,
            headerClassName: "table-header",
            renderCell: (params: any) => {
                const onEdit = (e: any) => {
                    e.stopPropagation();
                };
                return (
                    <Box display={"flex"}>
                        <Tooltip title={"Edit"} arrow>
                            <IconButton disableFocusRipple={true} onClick={onEdit} sx={{color:'#444A6D'}} color="primary">
                                <EditIcon  />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Delete"} arrow>
                            <IconButton disableFocusRipple={true}  sx={{color:"#444A6D"}}>
                                <DeleteForeverOutlinedIcon  />
                            </IconButton>
                        </Tooltip>
                    </Box>
                );
            }
        }
    ]

    let debounceTimeout;
    const handelSearch = (e)=>{
        const search = e.target.value;
        if(!search){
            setTableData(state)
            return;
        }else{
            clearTimeout(debounceTimeout);
            debounceTimeout = setTimeout(() => {
                const filteredData =  tableData.filter((value)=>{
                    if(value.name.includes(search)){
                        return value;
                    }
                })
                setTableData(filteredData);

            }, 1000);
        }

    };

    return (

        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            padding: '16px',
        }}>
            <Box sx={{
                backgroundColor: 'white',
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',

            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-center',

                }}>
                    <TextField
                        margin="none"
                        fullWidth
                        name="search"
                        placeholder="Search names"
                        onChange={handelSearch}
                        slotProps={{
                            input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon sx={{color: "#444A6D"}}/>
                                    </InputAdornment>

                                ),
                            },
                        }}
                        sx={{

                            '&::placeholder': {
                                color:  '#444A6D',
                                fontWeight: '400',
                                fontsize: '16px',
                                lineHeight: '24px',
                            },
                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(13, 17, 242, 1)',
                            },

                            width: "210px",
                            height: "56px",
                            paddingTop: 0
                        }}

                    />
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px'
                    }}>
                        <FilterAltIcon sx={{color: "#444A6D", width: '16px', height: '16px'}}  />
                        <p style={{cursor:'pointer'}} onClick={handleClick}>Filter by:</p>
                        <Box>
                            <Box sx={{cursor:'pointer'}} onClick={handleClick}>
                                <ArrowDropDownIcon />
                            </Box>
                            <Menu
                                id="composition-button"
                                aria-labelledby="composition-button"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <MenuItem onClick={()=>handleClose('All')}>All</MenuItem>
                                <MenuItem onClick={()=>handleClose('today')}>Today</MenuItem>
                                <MenuItem onClick={()=>handleClose('yesterday')}>Testerday</MenuItem>
                                <MenuItem onClick={()=>handleClose('last7days')}>Last 7 days</MenuItem>
                                <MenuItem onClick={()=> handleClose('last14days')}>last 14 days</MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    {tableData && <Tables headers={headers} rows={tableData} editHeader={editHeader}/>}
                </Box>
            </Box>

        </Box>
    )
})