import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import { Statistics }                      from "../DoctorPage/Statistics";
import { SnackbarProvider }                from "notistack";
import { LeftBar }                         from "../LeftBar";
import React, { useContext }               from "react";
import { AuthContext }                     from "../../context/Authprovider";
import { LogIn }                           from "../DoctorPage/DoctorLogin/LogIn";
import { SmsConfig }                       from "../DoctorPage/smsConfig";
import { Referral }                        from "../DoctorPage/Referral";
import { ContentList }                     from "../DoctorPage/Contetnt";
import { ContactUs }                       from "../DoctorPage/contactUs";
import {TopBar} from "../topBar";
import {Box} from "@mui/material";
import {BottomBar} from "../bottomBar";
import {Patients} from "../DoctorPage/Patients";

function PageWrapper() {



  return (
    <Box sx={{
        backgroundColor: "rgba(241, 241, 244, 1)",
        height: "100vh",
        overflow:"scroll",
    }}>
        <Box sx={{
            display:{
                lg: 'none'
            }
        }}>
            <TopBar version={'Keppy Pro'} />
            <BottomBar  />
        </Box>
        <Box sx={{
            width:'100%',
            display: {
                xs: 'none',
                sm: 'none',
                lg:'flex'
            }}}>
            <LeftBar/>
            <Box width={'100%'} overflow={"scroll"} marginLeft={'204px'} >
                <TopBar version={'Keppy Pro'} />
              <Box overflow={"scroll"} height={"100%"} marginTop={"80px"}>
                <Outlet/>
              </Box>
            </Box>
        </Box>
        <Box sx={{
            display:{
                xs: 'block',
                sm: 'block',
                lg:'none'
            }
        }}>
            <Box overflow={"scroll"} height={"100%"} marginTop={"80px"}>
                <Outlet/>
            </Box>
        </Box>

        {/*need to add buttom bar and change css 1200 ic nerqev */}
    </Box>
  );
}

function DoctorRouter({isLoggedIn}) {
    return (
        <SnackbarProvider maxSnack={3}
                          anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right"
                          }}>
            <Routes>
                {!isLoggedIn ? (
                    <>
                        <Route path="logIn" element={<LogIn/>}/>
                        <Route path="*" element={<Navigate replace to="logIn"/>}/>
                    </>
                ) : (
                    <Route path="*" element={<PageWrapper/>}>
                        <Route path="statistics/*" element={<Statistics/>}/>
                        <Route path="sms-conf/*" element={<SmsConfig/>}/>
                        <Route path="content/*" element={<ContentList/>}/>
                        <Route path="ref/*" element={<Referral/>}/>
                        <Route path="contactus/*" element={<ContactUs/>}/>
                        <Route path="patients/*" element={<Patients/>}/>
                        <Route path="*" element={<Navigate replace to="statistics"/>}/>
                    </Route>
                )}
            </Routes>
        </SnackbarProvider>
    );
}

export default DoctorRouter;

