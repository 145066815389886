import React, {useCallback, useEffect} from "react";
import {Header} from "../../DoctorHeader/Header";
// import { IEditProps, Table }             from "../../Table";
import {Field, Form, FormSpy} from "react-final-form";
import {FORM_ERROR} from "final-form";
import api from "../../../Api";
import {REFERRALS_PATH} from "../../../NetService/doctorConstants";
import Button from '@mui/material/Button';
import {useSnackbar} from "notistack";
import TextField from "@mui/material/TextField";
import {TableTest} from "../../DoctorTable";
import {Box, IconButton, Tooltip} from "@mui/material";
import {Tables} from "../../Table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {Simulate} from "react-dom/test-utils";
import input = Simulate.input;

interface IDate {
    updatedAt: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    status: string;
    id?:any,
}


export const Referral = React.memo(() => {
        //todo mekel es reset State ic azatvi, vonc nayum em petqakan ban chi
        const [reset, setReset] = React.useState(false);
        const [data, setData] = React.useState<IDate[]>([]);
        // const [edit, setEdit] = React.useState<IEditProps>();
        const {enqueueSnackbar} = useSnackbar();


        const headers =[
            {
                headStyle: { fontSize: '12px',
                    fontWeight: 500,
                    lineHeight: '18px',
                    textAlign: 'left',},
                field: "firstName",
                headerName: "First Name",
                width:'20%',
                headerClassName: "table-header",
                cellStyle: { fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    textAlign: 'left'}
            },
            {

                field: "lastName",
                headerName: "Last Name",
                width: '20%',
                cellStyle: {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    textAlign: 'left'
                    },
                headStyle: { fontSize: '12px',
                    fontWeight: 500,
                    lineHeight: '18px',
                    textAlign: 'left',},
                headerClassName: "table-header",
                // renderCell: (param:any)=>{
                //     console.log(param, 'param')
                //     if(param.row.age.length > 1){
                //         return param.row.age.join(", ");
                //     }else {
                //         return param.row.age;
                //     }
                // }
            },

            {
                field: "phoneNumber",
                headerName: "Phone Number",
                width: '20%',
                cellStyle: {
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    textAlign: 'left'
                },
                headStyle: { fontSize: '12px',
                    fontWeight: 500,
                    lineHeight: '18px',
                    textAlign: 'left',},
                height: 20,
                headerClassName: "table-header",
                // renderCell: (param: any) => {
                //     const url = param.value;
                //     return (
                //         <div className={'flex'}>
                //             <img style={{width: "64px", height: "38px", marginRight: "5px" }} src={param.row.image} alt={param.row.title} />
                //             <a className={'truncate'} href={url} target={"_blank"} rel={"noreferrer"}>{url}</a>
                //         </div>
                //     );
                // }

            },
            {
                headStyle: { fontSize: '12px',
                    fontWeight: 500,
                    lineHeight: '18px',
                    textAlign: 'left',},
                cellStyle: {
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '24px',
                            textAlign: 'left'},
                field: "status",
                headerName: "Status",
                width:'20%',
                headerClassName: "table-header",
                renderCell: (params: any) => {
                    if(params.row.status === "Waiting"){
                        return (
                            <p style={{color:'rgba(255, 183, 2, 1)'}}>{params.row.status}</p>
                        )
                    }else if(params.row.status === "Declined"){
                        return (
                            <p style={{color: 'rgba(181, 54, 0, 1)'}}>{params.row.status}</p>

                        )
                    }else if(params.row.status === "Accepted"){
                        return (
                            <p style={{color:'rgba(23, 207, 135, 1)'}}>{params.row.status}</p>
                        )

                    }else{
                        return (
                            <p style={{color:'rgba(167, 167, 167, 1)'}}>{params.row.status}</p>
                        )
                    }

                }
            },

        ]

        const editHeader = [{
            cellStyle: { fontSize: '14px',
                fontWeight: 500,
                lineHeight: '24px',
                textAlign: 'left'},
            headStyle: { fontSize: '12px',
                fontWeight: 500,
                lineHeight: '18px',
                textAlign: 'left',},
            field: "actions",
            headerName: "Actions",
            width: '20%',
            sortable: false,
            disableColumnMenu: true,
            headerClassName: "table-header",
            renderCell: (params: any) => {
                const onEdit = (e: any) => {
                    console.log(params.value.status,'statusssssss')

                    // setAdd(params.row);
                };
                return (
                    <Box display={"flex"}>
                        {
                            ( params.value.status === 'Waiting' && <Tooltip title={"Edit"} arrow>
                                <IconButton disableFocusRipple={true} onClick={()=>onEdit(params)} color="primary">
                                    <EditIcon  />
                                </IconButton>
                            </Tooltip>)
                        }
                        <Tooltip title={"Delete"} arrow>
                            <IconButton disableFocusRipple={true}  sx={{color:"#444A6D"}}>
                                <DeleteForeverOutlinedIcon  />
                            </IconButton>
                        </Tooltip>
                    </Box>
                );
            }
        }]

        const dataPreProcessing = useCallback((data: IDate[]) => {
            //
            let newData = data.map((item) => {
                return {
                    id: item.id,
                    updatedAt: new Date(item.updatedAt)
                        .toLocaleDateString()
                        .replace(/(\d+)\/(\d+)\/(\d+)/, '$2.$1.$3'),
                    firstName: item.firstName,
                    lastName: item.lastName,
                    phoneNumber: item.phoneNumber,
                    email: item.email,
                    status:
                        item.status === 'Referred'
                            ? 'Waiting'
                            : item.status === 'Not interested'
                                ? 'Declined'
                                : item.status === 'Purchased'
                                    ? 'Accepted'
                                    : item.status === 'Connected' ? 'Connected': item.status,
                };
            });
            setData(newData)
        }, [])

        async function getData() {
            try {
                const result = await api.getApi(REFERRALS_PATH);
                dataPreProcessing(result.results);
            } catch (e) {
                if (e instanceof Error) {
                    enqueueSnackbar(e.message, {variant: "error"});

                }
            } finally {
                // setEdit({});

            }
        }

        useEffect(() => {
            getData();
        }, []);

        const validation = (values: any) => {
            const errors: { [key: string]: string } = {}
            const validNumber = new RegExp(/^[\+][0-9]{8,}$/);
            if (!validNumber.test(values.phoneNumber)) {
                errors.phoneNumber = "Invalid phone number";
            }
            const validEmail = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
            if (!validEmail.test(values.email)) {
                errors.email = "Invalid email address";
            }
            return errors;
        };

        const onSubmit = async (values: any, form) => {
            if (values.edit) {
                try {
                    const url = REFERRALS_PATH + "/" + values.id;
                    await api.putApi(url, values);
                    enqueueSnackbar("Success", {variant: "success"});
                    form.reset()
                    // setEdit(undefined);

                    getData();
                } catch (e) {
                    if (e instanceof Error) {
                        enqueueSnackbar(e.message, {variant: "error"});
                        setReset(!reset);

                    }
                    return {[FORM_ERROR]: "Something went wrong. Please try again."};
                }
            } else {
                try {
                    if (values) {
                        const result = await api.postApi(REFERRALS_PATH, values);
                        if (result) {

                            dataPreProcessing([
                                {

                                    firstName: values.firstName,
                                    lastName: values.lastName,
                                    phoneNumber: values.phoneNumber,
                                    email: values.email,
                                    updatedAt: `${new Date()}`,
                                    status: "Referred",
                                },
                                ...data
                            ]);

                            form.reset()
                            enqueueSnackbar("Success", {variant: "success"});
                        }
                    }
                } catch (e) {
                    if (e instanceof Error) {
                        enqueueSnackbar(e.message, {variant: "error"});

                    }
                    return {[FORM_ERROR]: "Something went wrong. Please try again."};
                }
            }

        };
        const handleEdit = useCallback((value: any) => {
            // setEdit(value);
        }, []);






        return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            padding: '16px'
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
                padding: '16px',
                gap: '8px'
            }}>
                <p style={{
                    fontSize:' 16px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    textAlign: 'left',
                }}>Refer to</p>
                <p
                style={{
                    fontSize: '12px',
                    fontWeight: 500,
                    lineHeight: '18px',
                    textAlign: 'left',

                }}
                >Help Keppy to spread out all over the world. The provided information will be used for contact purposes only. Get extra <span style={{ color: 'rgba(23, 207, 135, 1)'}}>1-month subscription</span> after successful referral registration.</p>
                <Box>
                    <Form
                        onSubmit={onSubmit}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <Box sx={{display: 'flex', gap:"8px",
                                    width: '90%',
                                    flexDirection: {
                                        xs: 'column',
                                        sm: 'row',
                                        md: 'row',
                                        lg: 'row',
                                    }
                                }} >
                                    <Box sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '50%',
                                        },
                                        display: 'flex',
                                        gap: '8px',

                                    }}>
                                        <Field name={'firstName'}>
                                            {({input})=>(
                                                <TextField
                                                    {...input}
                                                    required={true}
                                                    sx={{
                                                        width: '50%',
                                                        '& .MuiOutlinedInput-root': {

                                                            '&:hover fieldset': {
                                                                borderColor: 'black',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'rgba(51, 55, 255, 1)',
                                                            },
                                                        },
                                                    }}
                                                    id="outlined-basic" variant="outlined" placeholder={'First Name'}/>
                                            )}
                                        </Field>
                                        <Field name={'lastName'}>
                                            {({input})=>(
                                                <TextField
                                                    {...input}
                                                    required={true}
                                                    sx={{
                                                        width: '50%',
                                                        '& .MuiOutlinedInput-root': {

                                                            '&:hover fieldset': {
                                                                borderColor: 'black',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'rgba(51, 55, 255, 1)',
                                                            },
                                                        },
                                                    }}
                                                    id="outlined-basic" variant="outlined" placeholder={'Last Name'} />
                                            )}

                                        </Field>
                                    </Box>

                                    <Box sx={{
                                        display: 'flex',
                                        gap: '8px',
                                        width: {
                                            xs: '100%',
                                            sm: '50%',
                                        },
                                    }}>
                                        <Field name={'phoneNumber'}>
                                            {({input})=>(
                                                <TextField
                                                    {...input}
                                                    required={true}
                                                    sx={{
                                                        width: '50%',
                                                        '& .MuiOutlinedInput-root': {

                                                            '&:hover fieldset': {
                                                                borderColor: 'black',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'rgba(51, 55, 255, 1)',
                                                            },
                                                        },
                                                    }}
                                                    id="outlined-basic"  variant="outlined" placeholder={'Phone Number'} />
                                            )}
                                        </Field>

                                        <Field name="email">
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    required
                                                    sx={{
                                                        width: '50%',
                                                        '& .MuiOutlinedInput-root': {
                                                            '&:hover fieldset': {
                                                                borderColor: 'black',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'rgba(51, 55, 255, 1)',
                                                            },
                                                        },
                                                    }}
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    placeholder="Email"
                                                    error={meta.touched && meta.error}
                                                    helperText={meta.touched && meta.error ? meta.error : ''}
                                                />
                                            )}
                                        </Field>

                                    </Box>
                                </Box>
                                <Box sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'end',
                                    padding: '10px 24px 10px 24px'
                                }}>
                                    <Button variant="contained" size="medium" type={'submit'} sx={{
                                        backgroundColor: 'rgba(51, 55, 255, 1)',
                                        borderRadius:'30px',
                                        fontWeight: 500,

                                    }}>
                                        Send
                                    </Button>
                                </Box>
                            </form>
                    )
                    }>
                    </Form>


                </Box>


            </Box>
            <Box sx={{
                width: '100%',
                backgroundColor: 'white',
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
            }}>
                <p style={{
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    textAlign: 'left'
                }}>Referrals</p>
                <Tables headers={headers} rows={data} editHeader={editHeader}/>

            </Box>
        </Box>
        );
    })

//
//     < div
// className = {"ml-[288px] w-[85%] h-screen flex flex-col gap-2 "} >
//     < Header
// className = {"pt-5 ml-5"}
// text = {"Referral"}
// />
// <div className={"max-h-[200px] overflow-y-scroll "}>
//     <TableTest columns={["Sent", "First Name", "Last  Name", "Phone Number", "Email", "Status"]} rows={data} edit={true}
//                tdClass={'text-start text-base  truncate'}
//                thClass={'text-start text-gray-light font-normal pr-2'}
//                editFunc={handleEdit}
//     />
//     {/*<Table*/}
//     {/*  name={"ref"}*/}
//     {/*  tableName={["Sent", "First Name", "Last  Name", "Phone Number", "Email", "Status"]}*/}
//     {/*  data={data}*/}
//     {/*  edit={handleEdit}/>*/}
// </div>
//
// <div className={"flex flex-col gap-9 px-7 pt-3"}>
//     <div>
//         <p>Help Keppy to spread out all over the world. The provided information will be used for contact purposes
//             ONLY. </p>
//         <p>We’ll offer you an extra 1-month subscription after successful referral registration. </p>
//     </div>
//     <div className={"flex gap-2 w-full"}>
//         <Form onSubmit={onSubmit}
//               validate={validation}
//             // initialValues={edit ? edit : {
//             //   lastName: "",
//             //   firstName: "",
//             //   phoneNumber: "",
//             //   email: ""
//             // }}
//
//               render={({handleSubmit, form}) => (
//                   <form onSubmit={handleSubmit} className={"w-full"}>
//                       <div className="flex flex-wrap gap-9 w-full  items-start max-lg:flex-col">
//                           <div className="flex-1 flex flex-col gap-2">
//                               <p className="font-extrabold truncate">First Name</p>
//                               <Field name="firstName">
//                                   {({input, meta}) => <TextField label="First Name" variant="outlined"
//                                                                  {...input}
//                                       // disabled={!edit}
//                                                                  required={true}
//                                                                  className={'w-full'}
//                                                                  error={meta.touched && Boolean(meta.error)}
//                                                                  sx={{
//                                                                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
//                                                                          borderColor: '#5000FF',
//                                                                      },
//                                                                      '& .MuiInputLabel-root.Mui-focused': {
//                                                                          color: '#5000FF',
//                                                                      }
//                                                                  }}
//                                   />}
//                               </Field>
//                           </div>
//
//                           <div className="flex-1 flex flex-col gap-2">
//                               <p className="font-extrabold truncate">Last Name</p>
//                               <Field
//                                   name="lastName"
//
//                               >
//                                   {({input, meta}) => (
//                                       <TextField label="Last Name" variant="outlined"
//                                                  {...input}
//                                           // disabled={!edit}
//                                                  required={true}
//                                                  className={'w-full'}
//
//                                                  sx={{
//                                                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
//                                                          borderColor: '#5000FF',
//                                                      },
//                                                      '& .MuiInputLabel-root.Mui-focused': {
//                                                          color: '#5000FF',
//                                                      }
//                                                  }}/>
//                                   )}
//                               </Field>
//                           </div>
//
//                           <div className="flex-1 flex flex-col gap-2">
//                               <p className="font-extrabold truncate">Phone Number</p>
//                               <Field name="phoneNumber">
//                                   {({input, meta}) => <TextField label="Phone Number" variant="outlined"
//                                                                  {...input}
//                                       // disabled={!edit}
//                                                                  required={true}
//                                                                  className={'w-full'}
//                                                                  error={meta.touched && Boolean(meta.error) && meta.dirty}
//                                                                  sx={{
//                                                                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
//                                                                          borderColor: '#5000FF',
//                                                                      },
//                                                                      '& .MuiInputLabel-root.Mui-focused': {
//                                                                          color: '#5000FF',
//                                                                      }
//                                                                  }}
//                                   />}
//                               </Field>
//                           </div>
//
//                           <div className="flex-1 flex flex-col gap-2">
//                               <p className="font-extrabold truncate ">Email</p>
//                               <Field name="email">
//                                   {({input, meta}) => <TextField label="Email" variant="outlined"
//                                                                  {...input}
//                                       // disabled={!edit}
//                                                                  required={true}
//                                                                  className={'w-full'}
//                                                                  error={meta.touched && Boolean(meta.error) && meta.dirty}
//                                                                  sx={{
//                                                                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
//                                                                          borderColor: '#5000FF',
//                                                                      },
//                                                                      '& .MuiInputLabel-root.Mui-focused': {
//                                                                          color: '#5000FF',
//                                                                      }
//                                                                  }}
//
//                                   />}
//                               </Field>
//                           </div>
//
//                           <div className="flex w-1/3 gap-4 mt-8 ml-auto max-lg:m-0">
//                               <Button variant="outlined" onClick={() => {
//                                   form.reset();
//                                   // setEdit(undefined);
//                               }}
//                                       sx={{
//                                           width: '100%',
//                                           color: '#5000FF',
//                                           borderColor: '#5000FF',
//                                           paddingY: '14px'
//                                       }}>Cancel</Button>
//                               <FormSpy subscription={{dirty: true}}>
//                                   {({dirty}) => (
//                                       <Button disabled={!dirty} type={'submit'} variant="contained" sx={{
//                                           color: 'white',
//                                           width: '100%',
//                                           backgroundColor: '#5000FF',
//                                           '&:disabled': {
//                                               color: '#191919',
//                                           },
//                                       }}>Save</Button>
//                                   )}
//                               </FormSpy>
//                           </div>
//                       </div>
//                       <div className={"absolute right-4 bottom-4"}>
//                           {/*{submitError && <DescriptionAlerts name="error"/>}*/}
//                           {/*{submitSucceeded && <DescriptionAlerts name="success"/>}*/}
//                       </div>
//                   </form>
//               )}
//         />
//     </div>
// </div>
// </div>