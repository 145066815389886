import React, {useState} from 'react'
import {Header} from "../../DoctorHeader/Header";
import {Card} from "../../Card";
import {useDoctorWizard} from "../../../hooks/doctorUseWizard";
import {TableTest} from "../../DoctorTable";
import {Box, MenuItem, Typography} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import countries from "../../CountrySelectorWithDetection/countries.json";
import {Select, TextField} from "mui-rff";
import {Field, Form} from "react-final-form";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";


export const SmsConfig = React.memo(() => {
    const [next, State] = useDoctorWizard();

    const [selectedCountry, setSelectedCountry] = useState<any>({
        "name": "United States",
        "dialCode": "+1",
        "isoCode": "US",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/us.svg"
    });
    const handleChange = (country: any) => {
        setSelectedCountry(country);
    };
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
            width: '100%',
        }}>

            <Box sx={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                padding: '16px',
                width:'100%'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                }}>
                    <Typography style={{
                        fontSize: '16px',
                        fontWeight: 500,
                        lineHeight: '24px',
                        textAlign: 'left',
                    }}>Message Testing</Typography>
                    <Typography style={{
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '18px',
                        textAlign: 'left'
                    }}>
                        You can test your set up message by inputting a custom phone number or by the system number.
                    </Typography>
                    <Form onSubmit={(vlaue) => {
                        console.log(vlaue);
                    }}
                          initialValues={{
                              phoneNumber: selectedCountry.dialCode,
                          }}
                          render={({handleSubmit}) => (
                              <form>
                                  <Box sx={{display: 'flex', gap: '16px', alignItems: 'center'}}>
                                      <Field name={'phoneNumber'}>
                                          {({input}) => (
                                              <TextField
                                                  {...input}
                                                  sx={{
                                                      width: '266px',
                                                      '& .MuiInputBase-root': {
                                                          paddingLeft: '0px',
                                                          marginRight: '0px',
                                                      },
                                                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                          borderColor: '#5000FF',
                                                      },
                                                      '& .MuiInputLabel-root.Mui-focused': {
                                                          color: '#5000FF',
                                                      },
                                                  }}

                                                  margin="none"
                                                  type={'tel'}
                                                  name="phoneNumber"
                                                  InputProps={{
                                                      startAdornment: (
                                                          <InputAdornment position="start">
                                                              <Box>
                                                                  <FormControl sx={{
                                                                      m: 1, maxWidth: "56px",
                                                                  }}>
                                                                      <Select
                                                                          sx={{
                                                                              width: '56px',
                                                                              '& .MuiSelect-select': {
                                                                                  paddingLeft: '0px', // Removes padding-left for the selected text
                                                                                  display: 'flex',
                                                                                  alignItems: 'center',
                                                                                  justifyContent: 'center',
                                                                              },
                                                                              '& .MuiOutlinedInput-notchedOutline': {
                                                                                  border: 'none',

                                                                              },
                                                                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                                  border: 'none',
                                                                              },
                                                                          }}
                                                                          name="phoneNumber"
                                                                          autoWidth
                                                                          // value={''}
                                                                          value={'+1'}
                                                                          renderValue={() => {
                                                                              const country = countries.find((item) => item.name === selectedCountry.name);
                                                                              return (
                                                                                  <Box sx={{
                                                                                      display: 'flex',
                                                                                      alignItems: 'center',
                                                                                      gap: '8px'
                                                                                  }}>
                                                                                      <img
                                                                                          src={country?.flag}
                                                                                          alt={country?.name}
                                                                                          style={{
                                                                                              width: '24',
                                                                                              height: '18px'
                                                                                          }}
                                                                                      />
                                                                                  </Box>
                                                                              );
                                                                          }}
                                                                      >
                                                                          <Box sx={{
                                                                              maxHeight: '100px',
                                                                              overflow: 'scroll'
                                                                          }}>
                                                                              {countries.map((country) => (

                                                                                  <MenuItem key={country.name}
                                                                                            value={`${country.dialCode}`}
                                                                                            onClick={() => handleChange(
                                                                                                country
                                                                                            )}>

                                                                                      <Box sx={{
                                                                                          display: 'flex',
                                                                                          alignItems: 'center',
                                                                                          gap: '8px'
                                                                                      }}>
                                                                                          <img
                                                                                              src={country.flag}
                                                                                              alt={country.name}
                                                                                              style={{
                                                                                                  width: 24,
                                                                                                  height: 16
                                                                                              }}
                                                                                          />
                                                                                          {country.dialCode}
                                                                                      </Box>
                                                                                  </MenuItem>
                                                                              ))}
                                                                          </Box>
                                                                      </Select>
                                                                  </FormControl>
                                                              </Box>
                                                          </InputAdornment>
                                                      ),
                                                  }}
                                              />
                                          )}
                                      </Field>
                                      {/*Test with Custom Number*/}
                                      <Typography onClick={handleSubmit} style={{
                                          fontWeight: 500,
                                          color: '#3337FF'
                                      }}>Test with Custom Number</Typography>
                                  </Box>

                              </form>
                          )}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',


                }}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            gap: '16px',
                            minWidth: '600px',
                            flexWrap: 'wrap',
                            height: '530px', // Set a fixed height to enable scrolling
                            overflow: 'scroll', // Enable scrolling when content overflows
                        }}
                    >
                        {[1, 2, 3,4,5,6].map((item, index) => (
                            <Box key={index} >
                                <Card id={'1'} surname={'Zargaryan'}/>
                            </Box>
                        ))}
                    </Box>
                    <Box sx={{
                        backgroundColor: 'white',
                        position: 'absolute',
                        bottom: {
                            sm: '0px',
                            lg: '0px'
                        },
                        width: {
                            sm: '100%',
                        },
                        marginLeft:{
                            sm: '0px',
                            md: '0px',
                            lg: "-16px"
                        },
                        padding: '16px',
                        display: 'flex',
                        justifyContent: 'end',
                        zIndex:"100"

                    }}>
                        <Button variant="contained" size="medium" type={'submit'} sx={{
                            backgroundColor: 'rgba(51, 55, 255, 1)',
                            borderRadius: '30px',
                            fontWeight: 500,


                        }}>
                            Save Changes
                        </Button>
                    </Box>
                </Box>

            </Box>
        </Box>


    )
})


//     < div
// className = {'ml-[288px] w-[85%] h-full flex '} >
//     < div
// className = {'w-full h-full '} >
//     < Header
// className = {'pt-5 ml-5'}
// text = {'Products'}
// />
// <div className={
//     'border-b-1'
// }>
//     <TableTest rows={[
//         {
//             registered: State?.subscription?.createdAt,
//             productType: State?.subscription?.productType,
//             subscription: State?.subscription?.subscription,
//             key: State?.key,
//             expired: State?.subscription?.expiredAt,
//             days: State?.subscription?.expiredDays,
//             firstName: State?.user?.firstName,
//             lastName: State?.user?.lastName,
//             phoneNumber: State?.user?.phoneNumber,
//             email: State?.user?.email,
//         }
//
//     ]}
//                columns={['Registered', 'Product Type', 'Subscription', 'Key', 'Expired', 'Days', 'First Name', 'Last  Name', 'Phone Number', 'Email']}
//                tdClass={'pr-2 text-start text-base'}
//                thClass={'text-start text-gray-light font-normal pr-2 '}/>
//     {/*<Table*/}
//     {/*    name={'communication'}*/}
//     {/*    tableName={['Registered', 'Product Type', 'Subscription', 'Key', 'Expired', 'Days', 'First Name', 'Last  Name', 'Phone Number', 'Email']}*/}
//     {/*    data={[*/}
//     {/*        {*/}
//     {/*            registered: State?.subscription?.createdAt,*/}
//     {/*            productType:  State?.subscription?.productType,*/}
//     {/*            subscription: State?.subscription?.subscription,*/}
//     {/*            key: State?.key ,*/}
//     {/*            expired:  State?.subscription?.expiredAt,*/}
//     {/*            days:  State?.subscription?.expiredDays,*/}
//     {/*            firstName: State?.user?.firstName,*/}
//     {/*            lastName:  State?.user?.lastName,*/}
//     {/*            phoneNumber:  State?.user?.phoneNumber,*/}
//     {/*            email: State?.user?.email ,*/}
//     {/*        }*/}
//     {/*    ]}/>*/}
// </div>
// <div className={'flex h-full w-full py-4  overflow-x-scroll'}>
//     <div className={'flex ml-3 space-x-4'}>
//     </div>
// </div>
//
// </div>
// </div>