import { useCallback, useState } from "react";
import React                     from "react";
import { Field, Form }           from "react-final-form";
import { useWizard }             from "../../../hooks/useWizard";
import { Button }                from "../../Button";
import { GoToPrevPage }          from "../../GoToPrevPage";
import { Input }                 from "../../Input";
import { youtubeVideoSerch }     from "../../../common/searchYoutubeVideos";
import { VideoItem }             from "../../VideoItem";
import { useLazyAsync }          from "../../../hooks/useAsync";
import api                       from "../../../Api";
import { useParams }             from "react-router-dom";
import { Header }                from "../../Header";
import {getYoutubeVideo} from "../../../common/getYoutubeVideo";
import {FORM_ERROR} from "final-form";

export const AddVideo = () => {
  const [selected, setSelected] = useState(null);
  const [allVideos, setAllVideos] = useState([]);
  const [searching, setSearching] = useState("");
  const { id } = useParams();
  const [value, next] = useWizard();
  const [call] = useLazyAsync((data) => api.getVideoQR(value.id, data), []);

  async function searchingInYoutube(values) {
    setSearching(values);
    const videos = await youtubeVideoSerch(values.search);
    if (videos) {
      setSearching("");
      setAllVideos(videos);
      const previous = localStorage.getItem("lastSearch");
      if(values.search && values.search !== previous ){
        localStorage.setItem("lastSearch", JSON.stringify(values.search));
      }
    } else {
      setSearching("");
    }
  }

  function getTimeByDuration(videoData) {
    const duration = videoData.duration;
    const regex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
    const match: any = duration.match(regex);
    const hours = match[1] ? parseInt(match[1]) : 0;
    const minutes = match[2] ? parseInt(match[2]) : 0;
    const seconds = match[3] ? parseInt(match[3]) : 0;

    return {
      hours,
      minutes,
      seconds,
    };
  }

  const searchYoutubeUrl = async (values) => {
    const match = values.search.match(/v=([^&]+)/);
    const videoId = match ? match[1] : null;
    const videoDetails =  await getYoutubeVideo(videoId);

    const time = getTimeByDuration(videoDetails);
    const minutes = time.hours ? time.hours * 60 + time.minutes : time.minutes;
    if (
        !(
            minutes < 30 ||
            videoDetails.orientation.width < videoDetails.orientation.height
        )
    ) {
      const data = await call({
        url: `https://www.youtube.com/watch?v=${videoId}`,
        productId: id
      });
      const selectedVideo = {
        title: videoDetails.title,
        src: videoDetails.orientation.url,
        channelTitle: videoDetails.channelTitle,
        id: videoId,
        qr: data?.qr,
        code: data?.code
      };
      next(`QR-code`, { selectedVideo: selectedVideo })
    }
    else{
      throw Error('Video  need to be minimum 30-minutes');
    }
  }

  const onSubmit = async (values: any) => {
    const url = new RegExp('^(https?:\\/\\/)?(www\\.)?(youtube\\.com\\/watch\\?v=|youtu\\.be\\/)[^&]+')
    if(url.test(values.search)){
      try{
        await searchYoutubeUrl(values)
      }catch (e){
        if(e instanceof Error){
          return {[FORM_ERROR]: e.message}
        }
      }
    }else{
      if (Object.keys(values).length === 0 ) {
        const previous = localStorage.getItem("lastSearch");

        if(( !!previous && previous !== "undefined")){
          const searchingData = {
            search: previous
          };
          await searchingInYoutube(searchingData);
        }
      } else {
        await searchingInYoutube(values);
      }
    }

  };

const onClean = useCallback(() => {
    localStorage.removeItem("lastSearch");
  }, []);

  const handleClick = async () => {
    const data = await call({
      url: `https://www.youtube.com/watch?v=${selected.id}`,
      productId: id
    });
    const { title, channelTitle, orientation } = selected;
    const selectedVideo = {
      title,
      src: orientation.url,
      channelTitle,
      id: selected.id,
      qr: data?.qr,
      code: data?.code
    };
    next(`QR-code`, { selectedVideo: selectedVideo });
  };

  return (
    <main className="w-content">

      <div className={"flex justify-between items-start"}>
        <GoToPrevPage/>
        <div className={"h-full"}>
          <img className={"w-[356] h-[100px]"} src={"/YoutubeLogo.svg?v=20241019"} alt="YouTube"/>
        </div>
        <div className={"w-42"}/>
      </div>
      <Form<FormData>
        onSubmit={onSubmit}
        render={({ handleSubmit, submitError }: any) => (
          <form onSubmit={handleSubmit}>
            <div className={"flex"}>
              <Field name={"search"}>
                {({ input, meta }) => (
                  <Input
                    type={"search"}
                    className={`text-xl text-start border-l-2 border-t-2 border-b-2 ${meta.error && meta.touched ? "border-red-primary" : "border-gray-primary"} rounded-tl-md rounded-bl-md `}
                    name={"search"}
                    placeholder={"Search"}
                    {...input}
                    onClean={onClean}
                  />
                )}
              </Field>
              <button type={"submit"}
                      className={"w-[20%] bg-blue-primary rounded-tr-md rounded-br-md flex justify-center items-center"}>
                <img src="/search.png?v=20241019" alt="Search"/>
              </button>
            </div>
            {submitError && <span className={'text-red-primary'}>{submitError}</span>}

            {allVideos.length === 0 ? <Header classNameDiv={"mt-4"}
                                              description2={`${!selected ? "Attention: The search results only include content that meets the system requirements: minimum 20-minute duration, landscape mode, and 1080p quality" : ""}`}
              />
              : null}
          </form>
        )}
      />
      <div className="flex flex-col  items-center  m-0 p-0 overflow-y-auto h-screen">
        <div className={"items-end"}>
          {allVideos.length !== 0 ? (
            <div>
              {
                allVideos.length ? allVideos.map((video: any, index: number) => <div key={index}
                                                                                     className={`${selected?.id === video.id ? " overflow-hidden border-solid border-2 border-blue-primary " : ""}`}
                                                                                     onClick={() => setSelected(video)}>
                  <VideoItem src={video.orientation.url} title={video.title}
                             channelTitle={video.channelTitle}
                             active={selected?.id === video.id}
                  />
                </div>) : null
              }
            </div>
          ) : null}
          {searching ? (
            <div key={"loader"} className="flex mt-4 justify-center min-h-screen">
              <div
                className="w-16 h-16 border-4 border-blue-primary border-solid  rounded-lg animate-spin"/>
            </div>
          ) : null
          }
        </div>
      </div>
      <div className="shrink-0">
        {selected && <Button text="Select" onClick={handleClick}/>}
      </div>
    </main>
  );
};