import {Box} from "@mui/material";
import  React from "react";
import Button from "@mui/material/Button";

interface ISigOut{

    isOpen: (out:boolean)=>void
}

export const SigOut = React.memo<ISigOut>(({isOpen})=>{
    return(

            <Box
                onClick={()=>isOpen(false)}
                sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100vh',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 10000,
            }}>
                <Box
                    onClick={(e) => e.stopPropagation()}
                    sx={{
                    width: '315px',
                    height: '154px',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    padding: '16px',
                    position: 'relative',

                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                    }}>
                        <p style={{
                            fontSize: '16px',
                            fontWeight: 500,
                            lineHeight: '24px',
                            textAlign: 'center',

                        }}>Log Out Confirmation</p>
                        <p style={{
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '18px',
                            textAlign: 'center',
                        }}>Are you sure you want to log out?</p>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: '8px'
                        }}>
                            <Button variant="contained" size="medium" type={'submit'} sx={{
                                backgroundColor: 'rgba(239, 235, 250, 1)',
                                borderRadius:'30px',
                                fontWeight: 500,
                                color: 'black',
                                boxShadow:'none',

                            }}  onClick={()=>{isOpen(false)}}>
                                Cancel
                            </Button>
                            <Button variant="contained" size="medium" type={'submit'} sx={{
                                backgroundColor: 'rgba(51, 55, 255, 1)',
                                borderRadius:'30px',
                                fontWeight: 500,
                                boxShadow:'none',

                            }}  onClick={()=>{isOpen(true)}}>
                                Log out
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
    )
})