import {Box, Typography} from "@mui/material";
import  React from "react";
import CloseIcon from "@mui/icons-material/Close";

interface IKeyFinderProps {
    isOpen: (isOpen:boolean) => void;
}

export const KeyFinder= React.memo<IKeyFinderProps>(({isOpen})=>{
    return (
        <Box>
            <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100vh',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000}}>
                <Box sx={{
                    padding: '16px',
                    width: '555px',
                    height: '396px',
                    backgroundColor: 'white',
                }}>
                    <Box sx={
                        {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                        }
                    }>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}>
                            <Typography style={{
                                fontSize: "16px",
                                fontWeight: 500,
                                lineHeight: '24px',
                                textAlign: 'left',
                            }}>Add Keppy VR System</Typography>
                            <CloseIcon style={{cursor: 'pointer'}} onClick={() => isOpen(false)}/>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center ',
                        }}>
                            <img src={'../Illustration.webp'}  />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                        }}>
                            <Typography style={{
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '24px',
                                textAlign: 'center',
                            }}>
                                Open the <span style={{
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '24px',
                                textAlign: 'center',
                            }}>Keppy application</span> interface on the controller and tap the <span style={{
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '24px',
                                textAlign: 'center',
                            }}>"Keppy Pro: xxx d"</span> value on the lower left side of the screen.
                            </Typography>
                            <Typography style={{fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '24px',
                                textAlign: 'center',}}>
                                An admin access screen will appear, where you can view the user's registration email and <span style={{
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '24px',
                                textAlign: 'center',
                            }}>KEY numbers.</span>
                            </Typography>
                        </Box>
                        <Typography onClick={()=>isOpen(false)} style={{
                            cursor:'pointer',
                            color: 'rgba(51, 55, 255, 1)',
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '24px',
                            textAlign: 'center',
                        }}>Back</Typography>
                    </Box>
                </Box>

            </Box>
        </Box>
    )
})

