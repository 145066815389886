import React, {useCallback} from "react";
import {Header} from "../../DoctorHeader/Header";
import {Field, Form, FormSpy} from "react-final-form";
import api from "../../../Api";
import {FORM_ERROR} from "final-form";
import {TICKETS_PATH} from "../../../NetService/doctorConstants";
import {useSnackbar} from "notistack";
import Button from '@mui/material/Button';
import {Box, MenuItem, TextareaAutosize, Typography} from "@mui/material";
import {Select, TextField} from "mui-rff";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';

export const ContactUs = React.memo(() => {
    const {enqueueSnackbar} = useSnackbar();
    const [file, setFile] = React.useState(null);


    const handelFileAdd = useCallback((fileName)=>{

    },[])

    const validation = (values: any) => {
        if (!values.title) {
            throw new Error("Please enter a title");
        } else if (!values.message) {
            throw new Error("Please enter a message");
        }
    };

    const onSubmit = async (value: any, form: any) => {
        try {
            validation(value);
            if (!value.title || !value.message) {
                throw new Error();
            }
            await api.postApi(TICKETS_PATH, value);
            enqueueSnackbar("Success", {variant: "success"})

        } catch (e) {
            if (e instanceof Error) {
                enqueueSnackbar(e.message, {variant: "error"})
            }
            return {[FORM_ERROR]: "Something went wrong"};
        }
        form.change("title", "");
        form.change("message", "");

    };

    return (
        <Box sx={{

            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            // gap: '16px',
            width: '100%',
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: "white",
                gap: '8px',
                padding: '16px',



            }}>
                <p style={{
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    textAlign: 'left',

                }}>Contact us</p>
                <Box>
                    <p style={{
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '18px',
                        textAlign: 'left'

                    }}>We are allays ready to hear from you: <span style={{color:'rgba(51, 55, 255, 1)'}}>+374 44 488 800</span></p>
                    <p style={{
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '18px',
                        textAlign: 'left'

                    }}>Working time Mon-Sat , 11AM-5PM , Zone GMT +4.</p>
                </Box>
                <Box sx={{}}>
                    <Form

                        onSubmit={onSubmit}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                                <Field name="title" type={'select'}>
                                    {({ input }) => (
                                        <Select
                                            sx={{


                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#5000FF',
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#5000FF',
                                                },
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                    color: '#5000FF',
                                                },

                                                width: '161px',
                                            }}
                                            {...input} // Connects the Field's input props to the Select component
                                            label="Email category"
                                        >
                                            <MenuItem value={'technicalSupport'}>Technical Support</MenuItem>
                                            <MenuItem value={'bugReport'}>Bug report</MenuItem>
                                            <MenuItem value={'informationUpdate'}>Information Update</MenuItem>
                                            <MenuItem value={'collaborationOffer'}>Collaboration Offer</MenuItem>
                                            <MenuItem value={'Other'}>Other</MenuItem>
                                        </Select>
                                    )}
                                </Field>


                                <TextField sx={{
                                    width: '332px',
                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#5000FF',
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: '#5000FF',
                                    },
                                }}  name="message" id="outlined-basic" label="Message here..." variant="outlined" />
                                <Box sx={{
                                    display: 'flex',
                                    gap: '8px',
                                    alignItems: 'center',
                                }}>
                                    <Box display={'inline'}>
                                        <Box component={'label'} sx={{
                                            display: 'inline-block',
                                            gap: '8px',


                                        }}>
                                            <Box style={{
                                                display: 'flex',
                                                alignItems: 'center',

                                                gap: '8px',
                                            }}>
                                                <input
                                                    onChange={(e) => setFile(e.target.value)}
                                                    type="file"
                                                    id="file-input"
                                                    style={{display: "none"}} // Hide the input
                                                />
                                                <AttachFileIcon sx={{
                                                    cursor: 'pointer',
                                                    width: '18px',
                                                    height: '18px',
                                                    color: '#3337FF'
                                                }}/>
                                                <Typography
                                                    sx={{
                                                        backgroundColor: "white",
                                                        color: "#3337FF",
                                                        fontWeight: 500,
                                                        border: "None",
                                                        cursor: "pointer",
                                                        paddingLeft: '0px',

                                                    }}
                                                >
                                            Attach file
                                        </Typography>
                                            </Box>

                                        </Box>
                                    </Box>
                                    {file && (
                                        <Box sx={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            width: '135px',
                                            borderRadius: "8px",
                                            border: '1px solid #3337FF',
                                            padding: '8px',

                                        }}>
                                            <Typography sx={{
                                                fontWeight: 500,
                                                fontSize: '10px',
                                                textWrap: 'nowrap',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',

                                            }}>
                                                {file}
                                            </Typography>
                                            <DeleteForeverOutlinedIcon
                                                sx={{width: '18px', height: '18px', color: '#444A6D', cursor: 'pointer'}}
                                                onClick={() => setFile(null)}
                                            />
                                        </Box>
                                    )}
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                }}>
                                <Button variant="contained" size="medium" type={'submit'} sx={{
                                        backgroundColor: 'rgba(51, 55, 255, 1)',
                                        borderRadius:'30px',
                                        fontWeight: 500,

                                    }}>
                                        Send
                                    </Button>
                                </Box>
                            </form>
                        )}
                    />

                </Box>
            </Box>
        </Box>
    );

});
// <div className={" ml-[288px] w-[85%] h-screen flex flex-col  pt-32 pl-20 max-lg:pl-10"}>
//     <div className={"w-[50%]"}>
//         <div>
//             <Header className={"mb-4 font-extrabold"} text={"Contact Us"}/>
//         </div>
//         <div className={"flex flex-col gap-y-5"}>
//             <div>
//                 <p className={"font-extrabold  "}>We are allays ready to hear from you: <span
//                     className={"text-blue-primary "}>+37444488800</span></p>
//                 <p className={"whitespace-nowrap"}>Working time Mon-Sat , 11AM-5PM , Zone GMT +4 </p>
//             </div>
//             <Form onSubmit={onSubmit}
//
//                   render={({handleSubmit, form}) => (
//                       <form onSubmit={handleSubmit}
//                             className={"min-w-[350px]"}>
//                           <div className={"flex  flex-col gap-y-4"}>
//                               <p className={"font-extrabold"}>Email title</p>
//                               <Select
//                                   name="title"
//                                   label="Titile"
//                                   required={true}
//                                   sx={{
//                                       '&:hover .MuiOutlinedInput-notchedOutline': {
//                                           borderColor: '#5000FF',
//                                       },
//                                       '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//                                           borderColor: '#5000FF',
//
//                                       },
//                                       '& .MuiInputLabel-root.Mui-focused': {
//                                           color: '#5000FF',
//                                       },
//
//                                   }}
//                               >
//                                   <MenuItem value="Technical Support">Technical Support</MenuItem>
//                                   <MenuItem value="Bug report">Bug report</MenuItem>
//                                   <MenuItem value="Information Update">Information Update</MenuItem>
//                                   <MenuItem value="Colaboration Offer">Collaboration Offer</MenuItem>
//                                   <MenuItem value="Other">Other</MenuItem>
//                               </Select>
//                               <Field name={"message"} component={"textarea"}
//                                      validate={(value) => (value ? undefined : "Please enter a message")}
//                               >
//                                   {({input, meta}) => (
//
//                                       <Box
//                                           sx={{
//                                               width: '100%',
//                                               borderRadius: '6px',
//                                               border: '1px solid #ccc',
//                                               padding: '8px',
//                                               '&:hover': {
//                                                   borderColor: '#5000FF',
//                                               },
//                                               '&:focus-within': {
//                                                   borderColor: '#5000FF',
//                                               },
//                                           }}
//                                       >
//                                           <TextareaAutosize
//                                               {...input}
//                                               name="message"
//                                               style={{
//                                                   minHeight: '150px',
//                                                   width: '100%',
//                                                   border: 'none',
//                                                   outline: 'none',
//                                               }}
//                                           />
//                                       </Box>
//
//                                   )}
//                               </Field>
//                           </div>
//                           <div className={"flex justify-between"}>
//                               <div>
//                               </div>
//                               <div className={"absolute right-4 bottom-4"}>
//                                   {/*{submitError && <DescriptionAlerts name="error"/>}*/}
//                                   {/*{submitSucceeded && (*/}
//                                   {/*    <>*/}
//                                   {/*        <DescriptionAlerts name="success"/>*/}
//                                   {/*    </>*/}
//                                   {/*)}*/}
//                               </div>
//                               <div className={"mt-10 flex w-1/2 gap-9"}>
//                                   <Button variant="outlined" onClick={() => {
//                                       form.reset();
//                                   }}
//                                           sx={{
//                                               width: '100%',
//                                               color: '#5000FF',
//                                               borderColor: '#5000FF',
//                                               paddingY: '14px'
//                                           }}>Cancel</Button>
//                                   <FormSpy subscription={{dirty: true}}>
//                                       {({dirty}) => (
//                                           <Button disabled={!dirty} type={'submit'} variant="contained" sx={{
//                                               color: 'white',
//                                               width: '100%',
//                                               backgroundColor: '#5000FF',
//                                               '&:disabled': {
//                                                   color: '#191919',
//                                               },
//                                           }}>Save</Button>
//                                       )}
//                                   </FormSpy>
//                               </div>
//                           </div>
//                       </form>
//                   )}
//             />
//
//
//         </div>
//     </div>
// </div>

