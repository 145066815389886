import React, {useEffect} from "react";
import {Field, Form, FormSpy} from "react-final-form";
import api from "../../Api";
import {FORM_ERROR} from "final-form";
import {PRODUCTS_PATH, TEMPLATE_PATH, TEMPLATE_TEST_PATH} from "../../NetService/doctorConstants";
import Button from '@mui/material/Button';
import EditIcon from "@mui/icons-material/Edit";
import {useSnackbar} from "notistack";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import {Avatar, Box, FormGroup, RadioGroup, styled} from "@mui/material";
import Switch, { SwitchProps } from '@mui/material/Switch';
import FormControl from "@mui/material/FormControl";



interface CardProps {
    id: string,
    surname: string,
    status?: string,
}

type FeedbackState = {
    feedback: string;
    enabled: any;
    link: any;
    reviewPlatform: string;
};

export const Card = React.memo<CardProps>(({id, surname}) => {
    const [edit, setEdit] = React.useState<boolean>(false);
    const [feedbackTemplates, setFeedbackTemplate] = React.useState<FeedbackState>();
    const {enqueueSnackbar} = useSnackbar();

    // const IOSSwitch = styled((props: SwitchProps) => (
    //     <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    // ))(({ theme }) => ({
    //     width: 52,
    //     height: 32,
    //     padding: 0,
    //     '& .MuiSwitch-switchBase': {
    //         padding: 0,
    //         margin: 2,
    //         transitionDuration: '300ms',
    //         '&:not(.Mui-checked)': {
    //             '& .MuiSwitch-thumb': {
    //                 backgroundColor: '#3337FF', // Thumb color when unchecked
    //                 borderColor: '#3337FF',    // Thumb border color when unchecked
    //             },
    //             '& + .MuiSwitch-track': {
    //                 backgroundColor: '#FFFFFF', // Track color when unchecked
    //             },
    //         },
    //         '&.Mui-checked': {
    //             transform: 'translateX(16px)',
    //             '& .MuiSwitch-thumb': {
    //                 backgroundColor: '#FFFFFF', // Thumb color when checked
    //             },
    //             '& + .MuiSwitch-track': {
    //                 backgroundColor: '#3337FF', // Track color when checked
    //                 opacity: 1,
    //             },
    //         },
    //     },
    //     '& .MuiSwitch-thumb': {
    //         boxSizing: 'border-box',
    //         width: 16,
    //         height: 16,
    //     },
    //     '& .MuiSwitch-track': {
    //         borderRadius: '100px',
    //         backgroundColor: '#E9E9EA',
    //         opacity: 1,
    //         transition: theme.transitions.create(['background-color'], {
    //             duration: 500,
    //         }),
    //     },
    // }));


    useEffect(() => {
        async function getFeedback() {
            try {
                const feedback = await api.getApi(PRODUCTS_PATH);
                const {feedbackTemplate} = feedback.results[0];
                const feedbackShow = {
                    feedback: feedbackTemplate.feedback === "yes" ? "yes" : "no",
                    enabled: feedbackTemplate.enabled,
                    link: feedbackTemplate.google || feedbackTemplate.yelp,
                    reviewPlatform: feedbackTemplate.google ? "google" : feedbackTemplate.yelp ? "yelp" : ""

                };
                setFeedbackTemplate(feedbackShow);
            } catch (e) {
                console.log(e);
            }
        }

        getFeedback();
    }, []);

    const alertError = (error: any) => {
        enqueueSnackbar(error, {variant: "error"});
    };
    const alertSuccess = () => {
        enqueueSnackbar("Success", {variant: "success"});

    };

    const templateSave = async (data: any) => {
        try {
            const send_data = {
                enabled: true,
                feedback: true,
                google: "",
                yelp: ""
            };

            send_data.enabled = data.enabled;
            send_data.feedback = data.feedback === "yes";
            send_data[data.reviewPlatform] = data.link;
            if (data.reviewPlatform === "google") {
                delete send_data["yelp"];
            } else {
                delete send_data["google"];
            }

            await api.putApi(TEMPLATE_PATH, send_data);
            alertSuccess();
        } catch (e) {
            throw e;
        }
    };
    const handleTestSubmit = async () => {
        try {
            await api.postApi(TEMPLATE_TEST_PATH);
            alertSuccess();

        } catch (e) {
            return (alertError(e));
        }
    };
    const validation = (value: any) => {
        if (!value.reviewPlatform || !value.link || !value.feedback) {
            throw new Error('Fill a required field');
        }
    }

    const onSubmit = async (value: any, form: any) => {
        try {
            validation(value)
            await templateSave(value);


        } catch (e) {
            if (e instanceof Error) {
                alertError(e.message)
                return {FORM_ERROR: e.message};
            }
        }

    };

    return (
            <Box sx={{
                maxWidth: '264px',
            }}>
                <Box sx={{ display: 'flex', gap:'8px',padding:"8px", backgroundColor: "#E6E6E6", justifyContent:"space-between"}}>
                    <Box sx={{display:"flex", gap:'8px', alignItems:'center'}}>
                        <Avatar sx={{ width: '24px', height: '24px' }} />
                        <span style={{
                            fontSize: '16px',
                            fontWeight: 500,
                            lineHeight: '24px',
                            textAlign: 'left',

                        }}>Dr. {surname}</span>
                    </Box>
                    <Switch/>
                </Box>
                <Box sx={{display:"flex", gap:'8px', backgroundColor:'#F1F1F4'}}>
                    <Box sx={{display:'flex', flexDirection:"column", padding:'16px',  gap: '8px'}}>
                        <p>Review platform</p>
                        <Box sx={{display:"flex",
                            flexDirection:"column",
                            gap:'8px'}}>
                            <FormControl>
                                <RadioGroup
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '8px',
                                        alignItems: 'center',
                                    }}
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                >
                                    <FormControlLabel value="yelp" control={<Radio sx={{
                                        '&.Mui-checked': {
                                            color: 'rgba(51, 55, 255, 1)',
                                        }

                                    }} />} label="Yelp" sx={{

                                    }} />
                                    <FormControlLabel value="google" control={<Radio
                                        sx={{
                                            '&.Mui-checked': {
                                                color: 'rgba(51, 55, 255, 1)',
                                            }
                                    }}
                                    />} label="Google" />
                                </RadioGroup>
                            </FormControl>
                            <TextField id="outlined-basic" label="Add link" variant="outlined" sx={{
                                '& .MuiInputBase-input':{
                                    backgroundColor: '#FFFFFF',
                                }
                            }} />
                        </Box>
                        <Box>
                            <p style={{
                                fontSize: '16px',
                                fontWeight: 500,
                                lineHeight: '24px',
                                textAlign: 'left',
                            }}>Procedure Feedback</p>
                            <p style={{
                                fontSize: '12px',
                                fontWeight: 400,
                                lineHeight: '18px',
                                textAlign: 'left',
                            }}>Patients will be able to estimate your procedure.</p>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox sx={{
                                    '&.Mui-checked': {
                                        color: 'rgba(51, 55, 255, 1)',
                                    }
                                }} />} label="Enable service" />
                            </FormGroup>
                        </Box>

                    </Box>
                </Box>
            </Box>
    );
});


// <main className={"shadow-lg flex h-full justify-center w-full"}>
//     <div className={" p-4 h-full rounded-lg pointer-events-auto"}>
//         <div
//             className={`rounded-t-lg ${feedbackTemplates?.enabled ? "bg-blue-primary" : "bg-gray-light"} flex p-4 justify-between`}>
//             <div>
//                 <p className={"text-white text-fontFamily-poppins"}>UserID: {id}</p>
//                 <p className={"text-white text-fontFamily-poppins"}>Dr.{surname}</p>
//                 <p className={"text-white text-fontFamily-poppins"}>Status:<span
//                     className={"underline"}>{feedbackTemplates?.enabled ? "Enable" : "Disable"}</span></p>
//             </div>
//
//             <div onClick={() => {
//                 setEdit(!edit);
//             }}>
//                 <div
//                     className={"w-10 h-10 bg-white rounded-lg flex justify-center items-center cursor-pointer"}>
//                     <EditIcon sx={{color: "#5000FF"}}/>
//                 </div>
//             </div>
//
//         </div>
//         <div className={"h-2/3"}>
//             <Form
//                 onSubmit={onSubmit}
//                 initialValues={feedbackTemplates ? feedbackTemplates : {
//
//                     enabled: true,
//                     feedback: "",
//                     reviewPlatform: "",
//                     link: ""
//
//                 }}
//                 render={({handleSubmit, form, submitSucceeded}) => (
//                     <form onSubmit={handleSubmit} className={"h-full"}>
//                         <div className={"p-2 w-full gap-2 flex flex-col justify-between"}>
//                             <div>
//                                 <label>
//
//                                     <Field name="enabled" type="checkbox"
//                                            disabled={!edit}>
//                                         {({input}) => (
//                                             <FormControlLabel
//                                                 control={
//                                                     <Checkbox
//                                                         {...input}
//                                                         sx={{
//                                                             '&.Mui-checked .MuiSvgIcon-root': {fill: '#5000FF'},
//                                                         }}
//                                                         disabled={!edit}
//                                                     />
//                                                 }
//                                                 label={'Enable SMS services'}
//                                             />
//                                         )}
//
//                                     </Field>
//                                 </label>
//                             </div>
//                             <div>
//                                 <p className={` ${!edit ? "text-gray-lightDoctor" : ""}`}>Select the review
//                                     platforms</p>
//                                 <div className={"flex mt-3"}>
//                                     <Field
//                                         name="reviewPlatform"
//                                         type="radio"
//                                         value="yelp"
//                                         disabled={!edit}
//                                         render={({input, meta}) => (
//
//                                             <FormControlLabel control={<Radio {...input} disabled={!edit} sx={{
//                                                 '&.Mui-checked .MuiSvgIcon-root': {fill: '#5000FF'}
//                                             }}/>}
//                                                               label="Yelp"/>
//                                         )}
//                                     />
//
//
//                                     <Field name="reviewPlatform" type="radio"
//                                            value="google" disabled={!edit} render={({input}) => (
//                                         <FormControlLabel control={<Radio {...input} disabled={!edit} sx={{
//                                             '&.Mui-checked .MuiSvgIcon-root': {fill: '#5000FF'}
//                                         }}/>}
//                                                           label="Google Review"/>)}/>
//
//
//                                 </div>
//                             </div>
//                             <div>
//                                 <Field name={"link"}>
//                                     {({input}) => (
//                                         <TextField label="Fill in Yelp review link" variant="outlined"
//                                                    {...input}
//                                                    disabled={!edit}
//                                                    className={'w-full'}
//                                                    sx={{
//                                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
//                                                            borderColor: '#5000FF',
//                                                        },
//                                                        '& .MuiInputLabel-root.Mui-focused': {
//                                                            color: '#5000FF',
//                                                        }
//                                                    }}/>)}
//                                 </Field>
//
//
//                                 {/*<Field name={'text'} component={'textarea'}>*/}
//                                 {/*    {({input, meta}) => (<AutoResizeTextArea input={input} meta={meta}*/}
//                                 {/*                                             className={'h-32 placeholder-black border border-gray-light'}*/}
//                                 {/*                                             maxLength={20}*/}
//                                 {/*                                             disabled={!selected}*/}
//                                 {/*                                             placeholder={'Text 20 character max'}/>)}*/}
//                                 {/*</Field>*/}
//                             </div>
//                             <div>
//                                 <p className={` ${!edit ? "text-gray-lightDoctor" : ""}`}>Do you want to send
//                                     feedback</p>
//                                 <div className={"flex mt-1"}>
//                                     <label className={"flex items-center cursor-pointer"}>
//
//                                         <Field name="feedback" type="radio" value={"yes"}
//                                                disabled={!edit}
//                                                render={({input, meta}) => (
//
//                                                    <FormControlLabel
//                                                        control={<Radio {...input} disabled={!edit} sx={{
//                                                            '&.Mui-checked .MuiSvgIcon-root': {fill: '#5000FF'}
//                                                        }}/>}
//                                                        label="Yes"/>
//                                                )}/>
//
//                                     </label>
//                                     <label className={"flex items-center cursor-pointer ml-1"}>
//                                         <Field name="feedback" type="radio" value={"no"}
//                                                disabled={!edit} render={({input, meta}) => (
//
//                                             <FormControlLabel control={<Radio {...input} disabled={!edit} sx={{
//                                                 '&.Mui-checked .MuiSvgIcon-root': {fill: '#5000FF'}
//                                             }}/>}
//                                                               label="No"/>
//                                         )}/>
//
//                                     </label>
//                                 </div>
//                             </div>
//                             <div>
//                                 {/*<p className={`${!selected? 'text-gray-lightDoctor':""}`}>Add a mobile number to test SMS</p>*/}
//                                 <div className={" mt-3"}>
//                                     {/*<div className={'flex gap-3 xl:flex-row'}>*/}
//                                     {/*    <Field name={'numberCode'}>*/}
//                                     {/*        {({input}) => (*/}
//                                     {/*            <Input*/}
//                                     {/*                {...input}*/}
//                                     {/*                classNameDiv={'mr-3'}*/}
//                                     {/*                className={'w-20 p-4 placeholder-black border border-gray-light'}*/}
//                                     {/*                placeholder={'+'}*/}
//                                     {/*                maxLength={3}*/}
//                                     {/*                disabled={!selected}*/}
//                                     {/*            />*/}
//                                     {/*        )}*/}
//                                     {/*    </Field>*/}
//                                     {/*    <Field name={'number'}>*/}
//                                     {/*        {({input}) => (*/}
//                                     {/*            <Input*/}
//                                     {/*                {...input}*/}
//                                     {/*                className={'p-4 placeholder-black border border-gray-light'}*/}
//                                     {/*                placeholder={'Mobile Number'}*/}
//                                     {/*                disabled={!selected}*/}
//                                     {/*            />*/}
//                                     {/*        )}*/}
//                                     {/*    </Field>*/}
//                                     {/*</div>*/}
//                                     <div className={"mt-2"}>
//                                         <button
//                                             disabled={!submitSucceeded}
//                                             type="button" // Change to button if handled separately
//                                             onClick={() => {
//                                                 const {numberCode, number} = form.getState().values;
//
//                                                 handleTestSubmit();
//
//                                             }}
//                                             className={`rounded-full px-2 bg-blue-light border-1 ${edit ? "border-blue-primary text-blue-primary" : "border-gray-light text-gray-light"}`}
//                                         >
//                                             test SMS
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//
//                             {edit && (
//                                 <div className={"flex mt-3 gap-2"}>
//                                     <Button variant="outlined" onClick={() => {
//                                         form.reset();
//                                         setEdit(!edit);
//                                     }}
//                                             sx={{
//                                                 width: '100%',
//                                                 color: '#5000FF',
//                                                 borderColor: '#5000FF'
//
//                                             }}>Cancel</Button>
//
//                                     <FormSpy subscription={{dirty: true}}>
//                                         {({dirty}) => (
//
//                                             <Button disabled={!dirty} type={'submit'} variant="contained" sx={{
//                                                 color: 'white',
//                                                 width: '100%',
//                                                 backgroundColor: '#5000FF',
//                                                 '&:disabled': {
//                                                     color: '#191919',
//                                                 },
//                                             }}>Save</Button>
//
//                                         )}
//
//
//                                     </FormSpy>
//
//                                 </div>
//                             )}
//
//                         </div>
//                     </form>
//                 )}
//             />
//         </div>
//     </div>
//
// </main>
