import React, {useCallback, useState} from "react";
import {Box, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {TextField} from "mui-rff";
import InputAdornment from "@mui/material/InputAdornment";
import KeyIcon from '@mui/icons-material/Key';
import {Field, Form} from "react-final-form";
import Button from "@mui/material/Button";
import {KeyFinder} from "../KeyFinder";
import {log} from "node:util";


interface IAddProductProps{


    isOpen: (open: boolean) => void;
}

export const AddProduct = React.memo<IAddProductProps>(({isOpen})=>{

    const [finder, setFinder] = useState(false);
    const keyFinder = useCallback((iOopen)=>{
        setFinder(iOopen);
    },[])
    return !finder ? ( <Box>
        <Box sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
        }}>
            <Box>
                <Box  sx={{
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}>
                    <Box sx={{
                        padding: '16px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                        <Typography style={{
                            fontSize: "16px",
                            fontWeight: 500,
                            lineHeight: '24px',
                            textAlign: 'left',
                        }}>Add Keppy VR System</Typography>
                        <CloseIcon style={{cursor:'pointer'}} onClick={() => isOpen(false)}/>
                    </Box>
                    <Box>
                        <img src={'../newGlass.webp'}/>
                    </Box>
                    <Box sx={{
                        padding: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                    }}>
                        <Form
                            onSubmit={() => {
                            }}
                            render={({handleSubmit}) => (

                                <form onSubmit={handleSubmit}
                                      style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: '8px',
                                      }}>
                                    <Field name={'Key'}>
                                        {({input}) => (
                                            <Box>
                                                <TextField
                                                    margin="none"
                                                    required
                                                    fullWidth
                                                    label="Key"
                                                    name="key"
                                                    slotProps={{
                                                        input: {
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <KeyIcon sx={{color: "#444A6D"}}/>
                                                                </InputAdornment>
                                                            ),
                                                        },
                                                    }}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#5000FF',
                                                        },
                                                        '& .MuiInputLabel-root.Mui-focused': {
                                                            color: '#5000FF',
                                                        },
                                                        paddingTop: 0
                                                    }}

                                                />
                                            </Box>
                                        )}
                                    </Field>
                                    <Box sx={{
                                        display: "flex",
                                        gap: "8px",
                                    }}>
                                        <Field name={'name'}>
                                            {({input}) => (
                                                <Box>
                                                    <TextField
                                                        margin="none"
                                                        required
                                                        fullWidth
                                                        label="Doctor Name"
                                                        name="name"
                                                        sx={{
                                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#5000FF',
                                                            },
                                                            '& .MuiInputLabel-root.Mui-focused': {
                                                                color: '#5000FF',
                                                            },
                                                        }}
                                                    />
                                                </Box>
                                            )}
                                        </Field>
                                        <Field name={'name'}>
                                            {({input}) => (
                                                <Box>
                                                    <TextField
                                                        margin="none"
                                                        required
                                                        fullWidth
                                                        label="Optional Name"
                                                        name="optional"
                                                        sx={{
                                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#5000FF',
                                                            },
                                                            '& .MuiInputLabel-root.Mui-focused': {
                                                                color: '#5000FF',
                                                            },
                                                        }}
                                                    />
                                                </Box>

                                            )}
                                        </Field>

                                    </Box>
                                    <Typography onClick={()=>setFinder(true)} style={{
                                        fontSize: '14px',
                                        cursor: 'pointer',
                                        fontWeight: 500,
                                        lineHeight: '24px',
                                        textDecorationLine: 'underline',
                                        textAlign: 'end',
                                        color: 'rgba(51, 55, 255, 1)',
                                    }}>Where to find information?</Typography>
                                    <Button variant="contained" size="medium" type={'submit'} sx={{
                                        backgroundColor: 'rgba(51, 55, 255, 1)',
                                        borderRadius:'30px',
                                        fontWeight: 500,
                                        boxShadow:'none',
                                    }}>
                                        Add Product
                                    </Button>
                                </form>)}
                        />

                    </Box>
                </Box>
            </Box>
        </Box>
    </Box>):(<KeyFinder isOpen={keyFinder}/>)



})