import * as React from 'react';
import { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import api from '../../../Api';
import {Box, Typography} from "@mui/material";
import {STATISTICS_PATH} from "../../../NetService/doctorConstants";
import Button from "@mui/material/Button";

interface LineChartProps {

    setStatistics?:any
}
interface DataItem {
    duration: number;
    experience: number;
    createdAt: string;
}
interface IDate{
    start?: any;
    end?: any;
}

function fillDate(data: DataItem[], setChartData: any,setStatistics:any) {
    const axis: string[] = [];
    const fail: number[] = [];
    const accept: number[] = [];
    const durationArray: number[] = [];

    data.forEach((item: DataItem) => {
        const { duration, experience, createdAt } = item;
        const isoDate = new Date(createdAt);
        const formattedDate = isoDate.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });

        if (axis[axis.length - 1] !== formattedDate) {
            axis.push(formattedDate);
            fail.push(0);
            accept.push(0);
        }

        if (duration < 120 || experience < 2) {
            fail[fail.length - 1] += 1;
        } else {
            accept[accept.length - 1] += 1;
        }
        durationArray.push(duration);


    });

    const totalArray = fail.map((f, index) => f + accept[index])
    const totalCount = totalArray.reduce((acc, val) => acc + val, 0)


    const cardData = {
        passed: accept.reduce((acc, val) => acc + val, 0),
        fail: fail.reduce((acc, val) => acc + val, 0),
        total: totalCount,
        duration: Math.ceil(durationArray.reduce((acc, val) => acc + val, 0)/durationArray.length),
        day: totalCount/totalArray.length,

    }
    if (axis.length === 1) {
        axis.unshift(axis[0]);
        fail.unshift(fail[0]);
        totalArray.unshift(totalArray[0]);
        accept.unshift(accept[0]);
    }

    const updatedChartData = {
        xAxis: axis,
        failed: fail,
        accepted: accept,
        total: totalArray,
    };

    setStatistics(cardData)
    setChartData(updatedChartData);
}




function queryCreator(from: any, to?: any){

    let whereParam;
    if (to) {
        from = new Date(from);
        to = new Date(to);
        to.setHours(23,59,59,999)
        if (from > to) {
            whereParam = JSON.stringify({
                createdAt: {
                    "$gt": to.toISOString(),
                    "$lt": from.toISOString()
                }
            });
        } else {
            whereParam = JSON.stringify({
                createdAt: {
                    "$gt": from.toISOString(),
                    "$lt": to.toISOString()
                }
            });
        }

    } else  {
        from = new Date(from);
        to = new Date(from);

        to.setHours(23,59,59,999);
        whereParam = JSON.stringify({
            createdAt: {
                "$gt": from.toISOString(),
                '$lt': to.toISOString()
            }
        });
    }

    return  `${STATISTICS_PATH}?where=${whereParam}`
}






const BasicArea: React.FC<LineChartProps> = ({setStatistics}) => {
    const [chartData, setChartData] = useState({
        xAxis: [],
        failed: [],
        accepted: [],
        total: [],
    });
    const [date, setDate] = useState<IDate>();
    const canvasRef = useRef(null);
    const chartInstance = useRef(null);

    const handelButtonClick = (date:string)=>{
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        let startDate = new Date(today);


        if (date === 'today') {
            startDate = new Date(today);
        } else if (date === 'yesterday') {
            startDate.setDate(today.getDate() - 1);
        } else if (date === 'last7days') {
            startDate.setDate(today.getDate() - 7);
        } else if (date === 'last14days') {
            startDate.setDate(today.getDate() - 14);
        } else if (date === 'last30days') {
            startDate.setDate(today.getDate() - 14);
        }else if (date === 'last90days') {
            startDate.setDate(today.getDate() - 14);
        }else if (date === 'thisMonth') {
            startDate = new Date(today.getFullYear(), today.getMonth(), 1)
            console.log(startDate)
        }else if (date === 'thisYear') {
            startDate = new Date(today.getFullYear(), 0, 1)
        }
        setDate({ start: startDate, end: today })
    }

    useEffect(() => {
        async function getData() {
            let response;
            try {
                if (date) {
                    response = await api.getApi(queryCreator(date.start, date.end));
                } else {
                    const currentDate = new Intl.DateTimeFormat().format(new Date());
                    response = await api.getApi(queryCreator(currentDate));
                }
                fillDate(response.results, setChartData,setStatistics);
            } catch (e) {
                console.error("Error fetching data:", e);
            }
        }
        getData();
    }, [date]);


    useEffect(() => {
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext("2d");
            chartInstance.current = new Chart(ctx, {
                type: "line",
                data: {
                    labels: chartData.xAxis,
                    datasets: [
                        {
                            data: chartData.failed,
                            borderColor: "#979797",
                            tension: 0.4,
                            fill: false,
                        },
                        {
                            data: chartData.accepted,
                            borderColor: "#96A5B8",
                            tension: 0.4,
                            fill: false,
                        },
                        {
                            data: chartData.total,
                            borderColor: "#5000FF",
                            tension: 0.4,
                            fill: false,
                        },
                    ],
                },
                options: {
                    plugins: {
                        legend: { display: false },
                    },
                },
            });
        }
    }, [chartData]);

    return (
        <Box sx={{
            width: '100%',
            padding:'16px',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',
                textAlign: 'left',
            }}>Statistics per dates</Typography>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '8px',
            }}>
                <Button variant="contained" size="medium"  onClick={()=>handelButtonClick('toDay')}  sx={{
                    height: '32px',
                    width: '89px',
                    backgroundColor: 'rgba(239, 235, 250, 1)',
                    borderRadius:'8px',
                    fontWeight: 500,
                    color: 'black',
                    textTransform: 'none',
                    boxShadow: 'none'
                }}>Today</Button>
                <Button variant="contained" size="medium" onClick={()=>handelButtonClick('yesterday')}   sx={{
                    height: '32px',
                    width: '95px',
                    backgroundColor: 'rgba(239, 235, 250, 1)',
                    borderRadius:'8px',
                    color: 'black',
                    textTransform: 'none',
                    boxShadow: 'none'
                }}>Yesterday</Button>
                <Button variant="contained" size="medium" onClick={()=>handelButtonClick('last7days')}  sx={{
                    height: '32px',
                    width: '130px',
                    backgroundColor: 'rgba(239, 235, 250, 1)',
                    borderRadius:'8px',
                    color: 'black',
                    textTransform: 'none',
                    boxShadow: 'none'
                }}>Last 7 days</Button>
                <Button variant="contained" size="medium" onClick={()=>handelButtonClick('last14days')}  sx={{
                    height: '32px',
                    width: '133px',
                    backgroundColor: 'rgba(239, 235, 250, 1)',
                    borderRadius:'8px',
                    color: 'black',
                    textTransform: 'none',
                    boxShadow: 'none'
                }}>Last 14 days</Button>
                <Button variant="contained" size="medium"  sx={{
                    height: '32px',
                    width: '133px',
                    backgroundColor: 'rgba(239, 235, 250, 1)',
                    borderRadius:'8px',
                    textTransform: 'none',
                    color: 'black',
                    boxShadow: 'none'
                }}>Last 30 days</Button>
                <Button variant="contained" size="medium" onClick={()=>handelButtonClick('last90days')}  sx={{
                    height: '32px',
                    width: '133px',
                    backgroundColor: 'rgba(239, 235, 250, 1)',
                    borderRadius:'8px',
                    color: 'black',
                    textTransform: 'none',
                    boxShadow: 'none'
                }}>Last 90 days</Button>
                <Button variant="contained" size="medium" onClick={()=>handelButtonClick('thisMonth')} sx={{
                    height: '32px',
                    width: '133px',
                    backgroundColor: 'rgba(239, 235, 250, 1)',
                    borderRadius:'8px',
                    color: 'black',
                    boxShadow: 'none',
                    textTransform: 'none',
                }}>This month</Button>
                <Button variant="contained" size="medium" onClick={()=>handelButtonClick('thisYear')} sx={{
                    height: '32px',
                    width: '133px',
                    backgroundColor: 'rgba(239, 235, 250, 1)',
                    borderRadius:'8px',
                    color: 'black',
                    boxShadow: 'none',
                    textTransform: 'none'
                }}>This year</Button>
                <Button variant="contained" size="medium"  sx={{
                    height: '32px',
                    width: '95px',
                    backgroundColor: 'rgba(239, 235, 250, 1)',
                    borderRadius:'8px',
                    color: 'black',
                    boxShadow: 'none',
                    textTransform: 'none'
                }}>Custom</Button>
            </Box>

            <Box>
                <canvas style={{width: '100%'}} ref={canvasRef}></canvas>
            </Box>
        </Box>)

};

export default BasicArea;
