import React from "react";
import { Box, Typography } from "@mui/material";

interface MediaCardDoctorProps {
    videoData: {
        [key: string]: {
            Cartoon: number;
            Nature: number;
            "360VR": number;
            Game: number;
            Other: number;
            Relax: number;
            Program: number;
        };
    };
}

export const MediaCardDoctor = React.memo<MediaCardDoctorProps>(({ videoData }) => {
    return (
        <Box sx={{ display: "flex", gap:"8px",}}>
            {/*"6-12", "13-18", "19-35", "36+"*/}
            {["3-5","6-12", "13-18", "19-35", "36+"].map((item, index) => (
                <Box key={index} sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    width: "20%",
                    gap: '12px',
                    backgroundColor: 'white'

                }}>
                    {/* Age Group */}
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: '100%',
                        alignItems: 'center',
                        gap:'8px',
                        borderBottom: "1px solid rgba(230, 230, 230, 1)",
                        // paddingLeft: '16px',
                        // paddingRight: '16px',

                    }}>
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                fontSize: { xs: '24px', md: '32px' },
                                lineHeight: { xs: '36px', md: '48px' },
                                color: 'rgba(51, 55, 255, 1)',
                            }}
                        >
                            {item}Y
                        </Typography>
                        <Box display="flex" alignItems="center" gap="8px">
                            <Typography sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                                color:  'rgba(6, 6, 3, 1)',
                            }}>
                                {` ${Object.values(videoData[item] as Record<string, number>)
                                    .reduce((sum, value) => sum + value, 0)} `}
                            </Typography>
                            <Typography style={{color:' rgba(68, 74, 109, 1)'}}>Videos</Typography>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: "column",
                        gap:'8px',
                        padding:'8px'



                    }}>
                        <Box display="flex" alignItems="center" gap="8px" >
                            <Typography style={{
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "24x",
                            }}>{videoData[item]?.Cartoon || 0}</Typography>
                            <Typography style={{color: 'rgba(68, 74, 109, 1)'}}>Cartoons</Typography>
                        </Box>

                        <Box display="flex" alignItems="center" gap="8px">
                            <Typography style={{
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "24x",
                            }}>{videoData[item]?.Nature || 0}</Typography>
                            <Typography style={{color: 'rgba(68, 74, 109, 1)'}}>Nature</Typography>
                        </Box>

                        <Box display="flex" alignItems="center" gap="8px">
                            <Typography style={{
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "24x",
                            }}>{videoData[item]?.["360VR"] || 0}</Typography>
                            <Typography style={{color: 'rgba(68, 74, 109, 1)'}}>360VR</Typography>
                        </Box>

                        <Box display={'flex'} alignItems="center" gap="8px">
                            <Typography style={{
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "24x",
                            }}>{videoData[item]?.Game || 0} </Typography>
                            <Typography style={{color: 'rgba(68, 74, 109, 1)'}}>Games</Typography>
                        </Box>

                        <Box display="flex" alignItems="center" gap="8px">
                            <Typography style={{
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "24x",
                            }}>{videoData[item]?.Other || 0}</Typography>
                            <Typography style={{color: 'rgba(68, 74, 109, 1)'}}>Other</Typography>
                        </Box>

                        {/* Uncomment if needed */}
                        {/*<Box>*/}
                        {/*    <Typography variant="body1">{videoData[item]?.Relax || 0}</Typography>*/}
                        {/*    <Typography variant="body1">Relax</Typography>*/}
                        {/*</Box>*/}
                        {/*<Box>*/}
                        {/*    <Typography variant="body1">{videoData[item]?.Program || 0}</Typography>*/}
                        {/*    <Typography variant="body1">Program</Typography>*/}
                        {/*</Box>*/}
                    </Box>
                </Box>
            ))}
        </Box>
    );
});
